<template>
  <div>
    <!-- 搜索条件 -->
    <div class="def-container find-list-container">
      <div class="findList" ref="findList">
        <!--筛选-->
        <el-dialog title="高级搜索条件" :visible.sync="searchVisible" width="50%" class="dialog">
          <div class="find-tabs">
            <span class="leftTxt pos1">我是</span>
            <span class="leftTxt pos2">我要找</span>
            <div class="visibleLable">
              <FilterGroup @change="changeSearch">
                <FilterCheckBox
                  ref="FilterCheckBox2"
                  v-model="searchInfo.data.qysx"
                  label="企业规模"
                  :list="getDictList('enterprise_size')"
                  valueKey="key"
                  :needAll="true"
                  :checkLists="checkListQylx"
                  :type="'1'"
                  fold
                ></FilterCheckBox>
                <FilterCheckBox
                  ref="FilterCheckBox1"
                  v-model="searchInfo.data.dq"
                  label="政策级别"
                  :list="getDictList('policy_level')"
                  valueKey="key"
                  :needAll="true"
                  :checkLists="checkListDq"
                  :type="'1'"
                  fold
                ></FilterCheckBox>
                <!-- 主管部门 -->
                <FilterCheckBox
                  ref="FilterCheckBox3"
                  v-model="searchInfo.data.deptcode"
                  label="主管部门"
                  :list="hydlList"
                  valueKey="deptCode"
                  labelKey="deptName"
                  :needAll="true"
                  :checkLists="checkListDept"
                  fold
                ></FilterCheckBox>
                <!-- 政策分类 -->
                <FilterCheckBox
                  ref="FilterCheckBox4"
                  v-model="searchInfo.data.zcfl"
                  label="政策分类"
                  :list="getDictList('sq_policy_xmlb')"
                  valueKey="key"
                  :needAll="true"
                  :checkLists="checkListZcfl"
                  fold
                ></FilterCheckBox>
              </FilterGroup>
            </div>
            <div class="search_table_con">
              <div class="flex-row-between chosenDiv"></div>
              <div class="seperation"></div>
              <div v-show="isShowTable" class="flex-row chosenDiv bgColor">
                <span>计算结果：</span>
                <div style="color: #ff9570; margin-left: 50px">
                  共<span class="numStyle">{{ policyNum }}</span
                  >份惠企政策，<span class="numStyle">{{
                    projectNumTotal
                  }}</span
                  >个项目，可获得资助资金总额最高为<span
                    v-if="money"
                    class="numStyle"
                    >: {{ money }}万元</span
                  >
                </div>
              </div>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="searchVisible = false">取 消</el-button>
            <el-button type="primary" @click="confirmClose"
              >确 定</el-button
            >
          </span>
        </el-dialog>
        <div class="find-tabs" v-show="showDetailFlag=='list'" style="min-height:50px;margin-bottom:-30px">
            <FilterGroup @change="changeSearch" v-show="cpu_paramsList.length">
              <div class="visibleLable2">
                <FilterItem label="已选择条件:" :needAll="false">
                  <div class="filter-params-inner">
                    <div class="filterItem-params-left">
                      <span
                        v-if="!cpu_paramsList.length"
                        style="margin-left: 20px"
                        >无</span
                      >
                      <a
                        v-else
                        class="pad-rl-1 search-params-clean"
                        @click="cleanParams"
                        >清空</a
                      >
                      <span
                        style="
                          color: #ff890b;
                          margin-left: 40px;
                          margin-left: 20px;
                        "
                        v-for="(item, index) in cpu_paramsList"
                        :key="index"
                      >
                        {{ item.label }}
                      </span>
                    </div>
                  </div>
                </FilterItem>
              </div>
            </FilterGroup>
          <div class="search_table_con">
            <div class="flex-row-between chosenDiv"></div>
            <div class="seperation"></div>
            <div v-show="isShowTable" class="flex-row chosenDiv bgColor">
              <span>计算结果：</span>
              <div style="color: #ff9570; margin-left: 50px">
                共<span class="numStyle">{{ policyNum }}</span
                >份惠企政策，<span class="numStyle">{{ projectNumTotal }}</span
                >个项目，可获得资助资金总额最高为<span
                  v-if="money"
                  class="numStyle"
                  >: {{ money }}万元</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 搜索结果列表 -->
    <div class="resultList flex-row-between" v-show="showDetailFlag=='list'">
      <div class="resultList-left">
        <el-table :data="tableData" style="width: 100%" @row-click="showDetails">
          <el-table-column label="" :show-header="false">
            <template slot-scope="scope">
              <div class="resultList-left-content">
                <div style="margin-bottom: 10px">
                  <span class="title">{{ scope.row.deptName }}</span>
                  <span class="headerTitle" v-html="scope.row.name"></span>
                </div>
                <div style="width: 100%">
                  <div class="tableDiv">
                    <div class="row">
                      <p class="borderRight">
                        <span class="boldTxt">发文字号：</span>
                        <span v-html="scope.row.fwzh"></span>
                      </p>
                      <p>
                        <span class="boldTxt">主管部门：</span>
                        <span v-html="scope.row.deptName"></span>
                      </p>
                    </div>
                    <div class="row">
                      <p style="width: 100%; border-right: none">
                        <span class="boldTxt">发布日期：</span>
                        <span>{{scope.row.publictime.split(" ")[0]}}</span>
                      </p>
                    </div>
                    <div class="row row-last">
                      <span class="boldTxt">搜索命中：</span>
                      <span
                        class="searchChose"
                        v-html="scope.row.content"
                      ></span>
                    </div>
                  </div>
                  <!-- <div class="subtilte flex-row-between">
                    <div>
                      <span v-html="scope.row.deptName"></span>-<span
                        v-html="scope.row.publictime"
                      ></span>
                    </div>
                    <span>相似信息 <span style="color: red">1</span> 条</span>
                  </div> -->
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          hide-on-single-page
          style="margin-top: 10px"
          background
          :page-size="pageSize"
          layout="prev, pager, next,total, jumper"
          prev-text="上一页"
          next-text="下一页"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="CurrentChange"
        >
        </el-pagination>
      </div>
      <!-- <div class="resultList-right">
        <div class="rank">
          <p>搜索排行</p>
          <div><i class="topthree">1</i><span>“两区”建设</span></div>
          <div><i class="topthree">2</i><span>市民对话一把手</span></div>
          <div><i class="topthree">3</i><span>防汛</span></div>
          <div><i>4</i><span>中招</span></div>
          <div><i>5</i><span>高招</span></div>
          <div><i>6</i><span>疫情防控</span></div>
        </div>
      </div> -->
    </div>
    <div class="details" v-show="showDetailFlag=='detail'">
      <div class="backBtn">
        <el-button type="primary" @click="toPolicy">返回</el-button>
      </div>
      <div v-html="handleContent2(detailContent)"> </div>
    </div>
  </div>
</template>

<script>
// import PublicForm from "@/components/publicForm"; // 公开公示表单
import { mapGetters } from "vuex";
import {handleContent2} from "@/utils/setContent.js"
import {
  FilterGroup,
  FilterCheckBox,
  FilterItem,
} from "../../policyLibrary/components/filter/index.js";

const tableData = [];
export default {
  name: "policyLibrary",
  components: {
    FilterGroup,
    FilterCheckBox,
    FilterItem,
  },
  props: {
    highlight: {
      type: Function,
    },
    keywords: {
      type: String,
    },
  },
  data() {
    return {
      showDetailFlag:"list",
      detailContent:"",//政策详情内容
      searchVisible: false, //搜索条件弹框
      tableData,
      pageSize: 5,
      total: 0,
      areaValue: [], //政策级别名称
      enterpriseAttributesValue: [], //企业属性
      enterpriseSizeValue: [], //企业规模
      growthCycleValue: [], //成长周期
      sysCategoryValue: [], //行业类别
      deptName: [], //主管部门需要传的参数数据
      hydlList: [], //主管部门
      policyClassificationValue: [], //政策分类需要传的参数数据
      radio: "",
      dialogVisible: false, //登录提示弹窗
      projectVisible: false, //项目列表展示
      showList: [],
      activeTab: "0", // 3大类 1 审批 2 资金 3 诉求
      collectList: [],
      isShowTable: false,
      // 搜索条件
      searchInfo: {
        data: {
          xmlb: "", // 项目类别
          cylb: "", // 产业类别
          deptcode: "", // 发布部门
          zcfs: "", // 支持方式
          temp_amount: "", // 资金限额
          zzxeleft: "", // 起始资金限额
          zzxeright: "", // 结束资金限额
          name: "", // 事项名称
          hyle: "", //行业类别
          zcfl: "", //政策分类
          dq: "", //地区
          qysx: "", //企业属性
          qygm: "", //企业规模
          growth: "", //成长周期
        },
        oldParams: {},
        deptList: [], // 职能部门列表
        amountList: [
          { value: "0", label: "1000万及以上" },
          { value: "1", label: "500万~1000万" },
          { value: "2", label: "100万~500万" },
          { value: "3", label: "100万及以下" },
          // { value: '4', label: '自定义' },
        ],
        areaList: [], //地区列表
        type: "0", // 搜索类型{'0':传统列表, '1':数字化列表}
      },
      dataLoading: false, // dataLoading状态位
      // 分页
      pageInfo: {
        page: 1,
        size: 7,
        total: 0,
      },
      // 项目分页
      pageInfoProject: {
        pageNum: 1,
        pageSize: 5,
        total: 0,
      },
      searchdata: "", //搜索输入数据
      searchDataHyle: "", //搜索行业类别需要的数据
      searchDataZcfl: "", //搜索政策分类需要的数据
      searchDataDq: "", //搜索地区需要的数据
      searchDataDept: "", //搜索部门需要的数据
      searchDataQysx: "", //搜索企业属性需要的数据
      searchDataQygm: "", //搜索企业规模需要的数据
      searchDataGrowth: "", //搜索成长周期需要的数据
      checkListQylx: [],
      checkListDq: [],
      checkListDept: [],
      checkListZcfl: [],
      policyInfo: {
        data: [],
      },
      projectShowList: [], //项目数组
      serachProject: "", //项目搜索数据
      policyId: "", //政策id
      policyNum: "", //政策数量
      projectNumTotal: "", //项目数量
      ksbxm: "", //可申报项目
      wsbxm: "", //未申报项目
      money: "", //资助总额
      blIndex: true, //判断是否为首页跳转，去除2次加载
      i: 1,
      zcflList:[]
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
      getDictList: "dictionary/getDictList",
      getDictLabel: "dictionary/getDictLabel",
    }),
    /**
     * @description: 暂无数据状态位
     * @return {String}
     */
    cpu_noData() {
      if (!this.dataLoading && !this.policyInfo.data.length) {
        return "noData";
      }
      return "";
    },
    /**
     * @description: 产业类别列表
     */
    cpu_cylbList() {
      let list = JSON.parse(JSON.stringify(this.getDictList("sys_enter_type")));
      if (!!list.length) {
        list[0].key = "";
        list[0].label = "不限";
      }

      return list;
    },
    /**
     * @description: 已选择的搜索条件列表
     * @return {Array}
     */
    cpu_paramsList() {
      let list = [];
      // 行业类别
      if (this.searchDataHyle) {
        let arrData = [];
        let listlabel = [];
        let listvalue = [];
        if (this.searchDataHyle !== "") {
          arrData = this.searchDataHyle.split(",");
          arrData.forEach((item) => {
            listvalue.push(item);
            let label = this.getDictList("sys_category").find(
              (cv) => cv.key === item
            )?.label;
            listlabel.push(label);
            // this.sysCategoryValue.push(label)
          });
          list.push({
            type: "hyle",
            value: listvalue.join("，"),
            label: listlabel.join("，"),
          });
        }
      }
      // 政策分类
      if (this.searchDataZcfl) {
        let arrData = [];
        let listlabel = [];
        let listvalue = [];
        if (this.searchDataZcfl !== "") {
          arrData = this.searchDataZcfl.split(",");
          arrData.forEach((item) => {
            listvalue.push(item);
            let label =this.zcflList.find(
              (cv) => cv.key === item
            )?.label;
            listlabel.push(label);
          });
          list.push({
            type: "zcfl",
            value: listvalue.join(","),
            label: listlabel.join(","),
          });
        }
      }
      // 企业属性
      if (this.searchDataQysx) {
        let arrData = [];
        let listlabel = [];
        let listvalue = [];
        if (this.searchDataQysx !== "") {
          arrData = this.searchDataQysx.split(",");
          arrData.forEach((item) => {
            listvalue.push(item);
            let label = this.getDictList("enterprise_attributes").find(
              (cv) => cv.key === item
            )?.label;
            listlabel.push(label);
            // this.enterpriseAttributesValue.push(label)
          });
          list.push({
            type: "qysx",
            value: listvalue.join("，"),
            label: listlabel.join("，"),
          });
        }
      }
      // 企业规模(我是)
      if (this.searchDataQygm) {
        let arrData = [];
        let listlabel = [];
        let listvalue = [];
        if (this.searchDataQygm !== "") {
          arrData = this.searchDataQygm.split(",");
          arrData.forEach((item) => {
            listvalue.push(item);
            let label = this.getDictList("enterprise_size").find(
              (cv) => cv.key === item
            )?.label;
            listlabel.push(label);
            // this.enterpriseSizeValue.push(label)
          });
          list.push({
            type: "qygm",
            value: listvalue.join("，"),
            label: listlabel.join("，"),
          });
        }
      }
      // 成长周期
      if (this.searchDataGrowth) {
        let arrData = [];
        let listlabel = [];
        let listvalue = [];
        if (this.searchDataGrowth !== "") {
          arrData = this.searchDataGrowth.split(",");
          arrData.forEach((item) => {
            listvalue.push(item);
            let label = this.getDictList("growth_cycle").find(
              (cv) => cv.key === item
            )?.label;
            listlabel.push(label);
            // this.growthCycleValue.push(label)
          });
          list.push({
            type: "growth",
            value: listvalue.join("，"),
            label: listlabel.join("，"),
          });
        }
      }
      // 政策级别
      if (this.searchDataDq) {
        let arrData = [];
        let listlabel = [];
        let listvalue = [];
        if (this.searchDataDq !== "") {
          arrData = this.searchDataDq.split(",");
          arrData.forEach((item) => {
            listvalue.push(item);
            let label = this.getDictList("policy_level").find(
              (cv) => cv.key === item
            )?.label;
            listlabel.push(label);
            // this.areaValue.push(label)
          });
          list.push({
            type: "dq",
            value: listvalue.join("，"),
            label: listlabel.join("，"),
          });
        }
      }
      // if (this.searchDataDept) {
      //   list.push({
      //     type: "dept",
      //     value: this.searchDataDept,
      //     label: this.searchInfo.deptList.find(
      //       (cv) => cv.deptid === this.searchDataDept
      //     )?.deptname,
      //   });
      // }
      //主管部门
      if (this.searchDataDept) {
        let arrData = [];
        let listlabel = [];
        let listvalue = [];
        if (this.searchDataDept !== "") {
          arrData = this.searchDataDept.split(",");
          arrData.forEach((item) => {
            listvalue.push(item);
            let label = this.hydlList.find(
              (cv) => cv.deptCode === item
            )?.deptName;
            listlabel.push(label);
            // this.searchDataDeptList.push(label);
          });
          list.push({
            type: "zgbm",
            value: listvalue.join("，"),
            label: listlabel.join("，"),
          });
        }
      }
      // 去掉第一个逗号
      list.filter((item) => {
        if (item.label[0] == "，") {
          item.label = item.label.substring(1);
        }
      });
      this.cleanPolicyParams();
      list.forEach((item) => {
        switch (item.type) {
          case "hyle":
            this.sysCategoryValue.push(item.label);
            break;
          case "qysx":
            this.enterpriseAttributesValue.push(item.label);
            break;
          case "qygm":
            this.enterpriseSizeValue.push(item.label);
            break;
          case "growth":
            this.growthCycleValue.push(item.label);
            break;
          case "dq":
            this.areaValue.push(item.label);
            break;
          case "zcfl":
            this.policyClassificationValue.push(item.label);
            break;
          case "zgbm":
            this.deptName.push(item.label);
            break;
          default:
            break;
        }
      });
      return list;
    },
    /**
     * @description: 表格 办理对象 显示文本
     * @param {String} bldx 办理对象
     * @return {String}
     */
    cpu_blzcText() {
      return function (bldx) {
        if (!bldx) return "";

        return this.getDictLabel("permit_sertype", bldx);
      };
    },
    /**
     * @description: 表格 企业注册类型 显示文本
     * @param {String} qyzclx 企业注册类型
     * @return {String}
     */
    cpu_qyzclxText() {
      return function (qyzclx) {
        if (!qyzclx) return "";

        let temp_qyzclx = qyzclx
          .split(",")
          .map((cv) => this.getDictLabel("sys_enterprise_registertype", cv))
          .join(",");
        return temp_qyzclx;
      };
    },
    cpu_hylbText() {
      return function (hylb) {
        if (!hylb) return "--";

        return hylb
          .split(",")
          .map((cv) => this.getDictLabel("sys_category", cv))
          .join(", ");
      };
    },
    /**
     * @description: 荣誉信息/资质认证要求 的显示文本
     * @param {String} ryxx 荣誉信息
     * @param {String} zzrzyq 资质认证要求
     * @return {String}
     */
    cpu_ryzzText() {
      return function (ryxx, zzrzyq) {
        if (ryxx && zzrzyq) {
          return `${ryxx} / ${zzrzyq}`;
        } else if (!ryxx && !zzrzyq) {
          return "";
        }
        return ryxx || zzrzyq;
      };
    },
    cpu_noText() {
      return function (val) {
        return val || "--";
      };
    },
  },
  methods: {
    handleContent2,
    confirmClose(){
      this.searchVisible = false;
      this.getProjectLibrary();
    },
    // 展示政策详情
    showDetails(row){
      this.showDetailFlag = "detail"
      this.detailContent = row.content2;
      this.$emit("showTop","detail");
    },
    toPolicy(){
      this.showDetailFlag = "list";
      this.$emit("showTop","list");
    },
    handleSizeChange(val) {
      val;
    },
    // 当前页改变时
    CurrentChange(pageNum) {
      let pageSize = this.pageSize;
      let url = "/elastic/search/sq-policy";
      let params = {
        pageNum: pageNum,
        pageSize: pageSize,
        keyWords: this.keywords,
        areaValue: this.areaValue,
        enterpriseAttributesValue: this.enterpriseAttributesValue,
        enterpriseSizeValue: this.enterpriseSizeValue,
        growthCycleValue: this.growthCycleValue,
        sysCategoryValue: this.sysCategoryValue,
        policyClassificationValue: this.policyClassificationValue,
        deptName: this.deptName,
        supportWayValue:[],
        sortBy: this.keyWords ? "" : "1",
      };
      let that = this;
      that.$httpApi
        .post(url, params)
        .then((res) => {
          console.log(res.data);
          that.total = res.total;
          that.$emit("getTotal", res.total);
          that.tableData = res.rows;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getProjectLibrary() {
      let url = "/elastic/search/sq-policy";
      let params = {
        pageNum: 1,
        pageSize: this.pageSize,
        keyWords: this.keywords,
        areaValue: this.areaValue,
        enterpriseAttributesValue: this.enterpriseAttributesValue,
        enterpriseSizeValue: this.enterpriseSizeValue,
        growthCycleValue: this.growthCycleValue,
        sysCategoryValue: this.sysCategoryValue,
        policyClassificationValue: this.policyClassificationValue,
        deptName: this.deptName,
        supportWayValue:[],
        // sortBy: this.keyWords ? "" : "1",
        sortBy: ""
      };
      let that = this;
      // params = JSON.stringify(params)
      that.$httpApi
        .post(url, params)
        .then((res) => {
          console.log(res.data);
          that.total = res.total;
          that.$emit("getTotal", res.total);
          that.tableData = res.rows;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 清空传参
    cleanPolicyParams() {
      this.areaValue = [];
      this.enterpriseAttributesValue = [];
      this.enterpriseSizeValue = [];
      this.growthCycleValue = [];
      this.sysCategoryValue = [];
      this.policyClassificationValue = [];
      this.deptName = [];
    },
    /**
     * @description 清空已选搜索项
     */
    cleanParams() {
      this.searchDataHyle = "";
      this.searchDataZcfl = "";
      this.searchDataDq = "";
      this.searchDataDept = "";
      this.searchDataQysx = "";
      this.searchDataQygm = "";
      this.searchDataGrowth = "";
      this.showList = [];
      this.pageInfo.total = 0;
      // this.getsearchList(1, this.pageInfo.size);
      // 清空选择的条件
      this.$refs.FilterCheckBox1.cleanItem();
      this.$refs.FilterCheckBox2.cleanItem();
      this.$refs.FilterCheckBox3.cleanItem();
      this.$refs.FilterCheckBox4.cleanItem();
      // this.$refs.FilterCheckBox5.cleanItem();
      this.cleanPolicyParams();
    },
    /**
     * @description: 删除已选搜索项
     * @param {Object} tag
     */
    closeParams(tag) {
      switch (tag.type) {
        case "temp_amount":
          this.searchInfo.data.temp_amount = "";
          this.searchInfo.data.zzxeleft = "";
          this.searchInfo.data.zzxeright = "";
          break;
        default:
          this.searchInfo.data[tag.type] = "";
          break;
      }
      this.searchInfo.oldParams = JSON.parse(
        JSON.stringify(this.searchInfo.data)
      );

      this.showList = [];
      this.pageInfo.total = 0;
      this.getsearchList(1, this.pageInfo.size);
    },
    /**
     * @description 合并表格行列
     * @param {Object} row 行
     * @param {Object} column 列
     * @param {Number} rowIndex 行下标
     * @param {Number} columnIndex 行下标
     */
    mergeTable({ row, column, rowIndex, columnIndex }) {
      // console.log('mergeTable:', row, column, rowIndex, columnIndex)
      if (column.type === "index" || column.property === "name") {
        // 是否与上一行同政策，是 则不占单元格
        if (0 < rowIndex && row.name === this.showList[rowIndex - 1].name) {
          return {
            rowspan: 1,
            colspan: 0,
          };
        }
        // 是否与下一行同政策，是 则占2行单元格
        let rowNum = 1;
        if (rowIndex < this.showList.length - 1) {
          for (let i = rowIndex + 1; i < this.showList.length; i++, rowNum++) {
            if (row.name !== this.showList[i].name) break;
          }
          return {
            rowspan: rowNum,
            colspan: 1,
          };
        }
      }
    },
    //查询所有的收藏列表,之后需要比对事项的收藏状态
    getCollectList() {
      if (this.userInfo) {
        let identify = this.userInfo.tyshxydm;
        let param = {
          identify: identify,
          type: "1,2,3,4,5,6",
          pageNum: 1,
          pageSize: 100,
        };
        let url = "/dev-api/collection/list";

        this.$httpApi
          .get(url, param)
          .then((res) => {
            // console.log('getCollectList:', res)
            if (res.code == 200) {
              this.collectList = res.rows;
            }
          })
          .catch((err) => {});
      }
    },
    //比对收藏列表,判断是否收藏
    isItemCollect(item) {
      let list = this.collectList;

      for (let i = 0; i < list.length; i++) {
        let collectItem = list[i];
        if (item.id == collectItem.collectionId) {
          // console.log(item.name + ":已收藏");
          return collectItem;
        }
      }
      return null;
    },
    /**
     * @description: 切换页码
     * @param {Number} page 页码
     */
    handleCurrentChange(page) {
      // this.showList = [];
      this.policyInfo.data = [];
      this.pageInfo.page = page;
      if (this.searchdata) {
        //搜索框查询
        this.searchPolicy();
      } else {
        this.getsearchList(page, this.pageInfo.size, true); //筛选查询
      }
    },
    handleProjectCurrentChange(page) {
      this.projectShowList = [];
      this.pageInfoProject.pageNum = page;
      this.projectShow(this.policyId);
    },
    /**
     * 监听declareCeil子组件点击收藏事件
     */
    clickIsFollow(item) {
      //判断是否登录
      if (this.$store.getters.getUserInfo) {
        //登录
      } else {
        //未登录
        this.dialogVisible = true;
      }
    },
    /*
     * 监听DeclareCeil子组件点击获取详情事件
     */
    clickCell(item) {
      // console.log("选择的是:", item);
      // alert("转跳")
      this.$store.commit("updateCurrentGuideId", item.id);
      if (this.activeTab == 0) {
        this.$router.push({
          path: "/guideSuqiu",
          query: {
            name: item.name,
            itemType: item.itemtype,
            isAdded: item.isAdded,
          },
        });
      } else {
        this.$router.push({
          path: "/guideSuqiu",
        });
      }
    },
    /**
     * 登录提示框点击确定登录，跳转登录页面
     */
    gotoLogin() {
      console.log("跳转登录页面");
      this.$router.push({
        path: "/login",
      });
    },
    /**
     * 根据专项名称关键字搜索
     */
    searchByKeyword(data) {
      this.searchInfo.data.name = data;
      this.searchInfo.oldParams = JSON.parse(
        JSON.stringify(this.searchInfo.data)
      );

      this.showList = [];
      this.pageInfo.total = 0;
      this.getListData(1, this.pageInfo.size);
    },
    /**
     * @description: 修改搜索条件
     */
    changeSearch(label, value) {
      // 特殊情况不需要触发搜索
      this.i = 1 + this.i;
      if (this.blIndex) {
        switch (label) {
          case "行业类别":
            this.searchDataHyle = value;
            break;
          case "政策分类":
            this.searchDataZcfl = value;
            break;
          case "政策级别":
            // case "我要找":
            // value = value.substring(1);
            this.searchDataDq = value;
            break;
          case "主管部门":
            this.searchDataDept = value;
            break;
          // case "企业属性":
          //   this.searchDataQysx = value;
          //   break;
          // case "我是":
          case "企业规模":
            this.searchDataQygm = value;
            break;
          // case "成长周期":
          //   this.searchDataGrowth = value;
          //   break;
          default:
            break;
        }
      } else if (this.i >= 4) {
        this.blIndex = true;
      }
    },
    /**
     * @description 查询职能部门列表
     */
    getDeptList() {
      let url = "/dev-api/sqzdManager/data/find/sq_policy_project_dept_list";
      let params = {};
      this.$httpApi
        .post(url, params)
        .then((res) => {
          // console.log('getDeptList:', res)
          if (res.data && !!res.data.length) {
            this.searchInfo.deptList = res.data || [];
          }
        })
        .catch((err) => {
          console.log("getDeptList error:", res);
        });
    },
    /**
     * @description: 查询政策列表
     */
    getAreaList() {
      let url = "/dev-api/cfginfo/region/cascaderTreeList";
      let params = {};
      let that = this;
      this.$httpApi
        .get(url, params)
        .then((r) => {
          let getdata = that.getTreeFlat(r.rows);
          this.searchInfo.areaList = getdata;
        })
        .catch((e) => {});
    },
    //给treeData扁平化处理拿对应数据
    getTreeFlat(arr, parent = false) {
      const dataList = [];
      const generateList = (data, deepcount) => {
        let num = deepcount;
        if (num < 2) {
          for (let i = 0; i < data.length; i++) {
            const { id, value, label, children } = data[i];
            if (!parent) dataList.push({ id, value, label });
            if (Array.isArray(children) && children.length > 0) {
              if (parent) dataList.push({ id, value, label });
              num++;
              generateList(children, num);
            }
          }
        }
      };
      generateList(arr, 0);
      return dataList;
    },
    // 查询政策
    getsearchList(page, size) {
      this.isShowTable = true;
      this.dataLoading = true;
      let that = this;
      let url = "/dev-api/business/sq-apply/policyLibraryMatching";
      let flag = false;
      setTimeout(function () {
        flag = true;
      }, 4000);
      that.policyId = "";
      let params = {
        areaid: that.searchDataDq, //区划id
        sysCategory: that.searchDataHyle, //行业类别
        enterpriseAttributes: that.searchDataQysx, //企业属性
        enterpriseSize: that.searchDataQygm, //企业规模
        growthCycle: that.searchDataGrowth, //成长周期
        pageNums: that.pageInfo.page,
        pageSizes: that.pageInfo.size,
        status: "1",
        search: that.searchdata,
      };
      if (
        that.searchDataDq == "" &&
        that.searchDataHyle == "" &&
        that.searchDataQysx == "" &&
        that.searchDataQygm == "" &&
        that.searchDataGrowth == ""
      ) {
        params.isChoose = false;
      } else {
        params.isChoose = true;
      }
      this.$httpApi
        .post(url, params)
        .then((r) => {
          that.policyInfo.data = r.pageInfo.list;
          that.policyInfo.data.forEach((item) => {
            item.star = 5;
          });
          let time = setInterval(() => {
            if (flag) {
              that.pageInfo.total = r.pageInfo.total;
              that.policyNum = r.pageInfo.total;
              that.projectNumTotal = r.sqPolicyNum.projectNumTotal;
              that.ksbxm = r.sqPolicyNum.ksbxm;
              that.wsbxm = r.sqPolicyNum.wsbxm;
              that.money = r.sqPolicyNum.money;
              this.dataLoading = false;
              clearInterval(time);
            }
          }, 1000);
        })
        .catch((e) => {});
    },
    // 根据条件查询
    searchPolicy() {
      let that = this;
      // let url = "/dev-api/business/sq-apply/squeryPerm";
      let url = "/dev-api/business/sq-apply/policyLibraryMatching";
      that.policyId = "";
      let params = {
        areaid: that.searchDataDq, //区划id
        // policyClassification: that.searchDataZcfl, //政策分类
        sysCategory: that.searchDataHyle, //行业类别
        // deptId: that.searchDataDept, //部门id
        enterpriseAttributes: that.searchDataQysx, //企业属性
        enterpriseSize: that.searchDataQygm, //企业规模
        growthCycle: that.searchDataGrowth, //成长周期
        // PAGE_CUR: page.toString(),
        // PAGE_ORDER: "",
        // PAGE_ROWS: size.toString(),
        // PAGE_TYPE: "P",
        pageNum: that.pageInfo.page,
        pageSize: that.pageInfo.size,
        status: "1",
        search: that.searchdata,
        dataLoading: false,
      };
      if (
        that.searchDataDq == "" &&
        that.searchDataHyle == "" &&
        that.searchdata == "" &&
        that.searchDataQysx == "" &&
        that.searchDataQygm == "" &&
        that.searchDataGrowth == ""
      ) {
        params.isChoose = false;
      } else {
        params.isChoose = true;
      }
      if (
        that.searchDataDq == "" &&
        that.searchDataHyle == "" &&
        that.searchDataQysx == "" &&
        that.searchDataQygm == "" &&
        that.searchDataGrowth == ""
      ) {
        params.isSearchFlag = true;
      }
      this.$httpApi
        .post(url, params)
        .then((r) => {
          that.policyInfo.data = r.list;
          that.policyInfo.data.forEach((item) => {
            item.star = 5;
          });
          that.pageInfo.total = r.total;
          that.policyNum = r.total;
        })
        .catch((e) => {});
    },
    // 查项目接口
    getItemList(policyid) {
      // this.getsearchList();
    },
    //根据政策id查询项目
    projectShow(policyId) {
      this.policyId = policyId;
      let url = "/dev-api/business/sq-apply/selectProjectList";
      let params = {
        policyId: policyId,
        pageSize: this.pageInfoProject.pageSize,
        pageNum: this.pageInfoProject.pageNum,
        serachProject: this.serachProject,
      };
      let that = this;
      that.$httpApi
        .post(url, params)
        .then((r) => {
          this.projectVisible = true;
          if (r) {
            that.projectShowList = r.list;
            that.pageInfoProject.total = r.total;
            that.policyNum = r.total;
          }
        })
        .catch((e) => {});
    },
    toProject(row) {
      this.$store.commit("updateCurrentGuideId", row.id);
      const { href } = this.$router.resolve({
        path: "/guideSuqiu",
        query: {
          id: row.id,
          // name: item.name,
        },
      });
      window.open(href, "_blank");
    },
    // 主管部门
    queryLabelDept() {
      let url = "/dev-api/enterprise-label/queryLabelDept";
      let params = {};
      this.$httpApi
        .post(url, params)
        .then((res) => {
          this.hydlList = res.data;
        })
        .catch((err) => {
          console.log("evaluate error:", err);
        });
    },
     getzcflList() {
      let url = "/dev-api/system/dict/getZcDictListByType";
      let params = {};
      this.$httpApi
        .get(url, params)
        .then((r) => {
          this.zcflList = r.data.slice(0, 6);
        })
        .catch((e) => {});
    },
  },
  created() {
     this.getzcflList()
    if (this.$route.params.searchParams) {
      //政策级别
      this.searchDataDq = this.$route.params.searchParams.searchDataDqT;
      this.checkListDq = this.$route.params.searchParams.checkListDq;
      // 企业类型
      this.searchDataQygm = this.$route.params.searchParams.searchDataQylxT;
      this.checkListQylx = this.$route.params.searchParams.checkListQylx;
      this.checkListQylx = this.checkListQylx.map((item) => {
        return item + 1;
      });
      // 主管部门
      this.searchDataDept = this.$route.params.searchParams.searchDataDeptT;
      this.checkListDept = this.$route.params.searchParams.checkListDept;
      // 政策分类
      this.searchDataZcfl = this.$route.params.searchParams.searchDataZcflT;
      this.checkListZcfl = this.$route.params.searchParams.checkListZcfl;

      this.blIndex = false;
    }
    this.getProjectLibrary();
    this.getItemList();
    this.$store.dispatch("dictionary/commonSetDic", {
      types: [
        "sq_policy_xmlb",
        "sys_enter_type",
        "sq_policy_zjlx",
        "permit_sertype", // 办理对象
        "sys_enterprise_registertype", // 注册类型
        "sys_category", // 行业类别(国民经济分类)
        "policy_level", //政策层级
        "enterprise_attributes", //企业属性
        "enterprise_size", //企业规模
        "growth_cycle", //成长周期
      ],
    });
    this.getDeptList();
    this.getAreaList();
    this.queryLabelDept(); //查询主管部门
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "@/styles/com/theme.scss";
@import "@/styles/com/flex.scss";
.seperation {
  height: 20px;
  background-color: #fff;
}
.searchList {
  .banner-info {
    height: 100%;
    display: flex;
    align-items: center;
    .input-search-bar {
      height: 55px;
      width: 50%;
      margin-bottom: 10px;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      background: #ffffff;
      margin-left: 74px;

      /* 输入框头部 */
      .input-search-pre {
        width: 120px !important;
        color: #404040;
        font-size: 16px;
        background: $color-fff;
        .el-input__inner {
          text-align: center !important;
          border: none !important;
          border-radius: 0 !important;
          color: #333;
          background-color: #ffffff !important;
        }
      }

      /* 输入框内容 */
      .input-search {
        flex: 1;
        .el-input__inner {
          border: none !important;
          border-radius: 0 !important;
          background-color: #ffffff !important;
        }
        input::-webkit-input-placeholder {
          color: #999999;
        }
      }
      /* 输入框按钮 */
      .input-search-btn {
        background: #e60000;
        height: 100%;
        width: 100px;
        border-radius: 0 !important;
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
        border: 2px solid #b6a99c !important;
        text-align: center;
        font-size: $font-size18;
        color: $color-fff;
        cursor: pointer;
        .el-button {
          font-weight: bold !important;
        }
      }
    }
  }
  .search-bar {
    margin: 40px;
    justify-content: flex-start;
    .s-input {
      width: 40% !important;
    }
  }
  .tabBgcolor {
    width: 100%;
    height: 60px;
    background-color: #df1d09;
    position: absolute;
    top: 248px;
  }
  .tool {
    position: absolute;
    top: 0;
    right: -5px;
    cursor: pointer;
  }
  .search-result {
    width: 1000px;
  }
  .s-container {
    width: 100%;
    min-height: 500px;
    padding: 20px 0;
    .el-menu-demo {
      margin-top: -20px;
    }
    .el-menu--horizontal > .el-menu-item.is-active {
      color: #fff;
      border-bottom: 2px solid #fff;
    }
    .resultTop {
      position: relative;
      cursor: pointer;
      margin-top: 30px;
      .underline {
        text-decoration: underline;
      }
      .toolTitle div {
        margin-right: 15px;
        span {
          margin-right: 15px;
        }
        .boldTxt {
          width: 220px;
          color: #000;
          font-weight: 700;
        }
      }
      .el-input__inner {
        width: 50%;
        border: none;
        color: #333;
        padding-right: 0;
      }
      .el-input__suffix {
        right: 60px;
      }
      input::-webkit-input-placeholder {
        color: #333;
      }
    }
    .find-list-container {
      margin-top: 14px;
      .el-input {
        input {
          background-color: #ffffff;
          border-radius: 0px !important;
        }
      }
      .el-input-group__append {
        button {
          background-color: rgb(75, 145, 233);
        }
      }
      .el-row {
        width: 360px;
        display: flex;

        .el-button {
          border: 0px;
          border-radius: 0px;
          width: 90px;

          .el-icon-bottom {
            margin-left: 15px;
          }

          &:hover {
            background-color: #2d8cf0;
          }
        }
      }
    }
    .filter-params-inner {
      display: -webkit-flex;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      ::v-deep .el-tag {
        margin-right: 5px;
        color: $col-theme;

        .el-tag__close {
          color: $col-theme;

          &:hover {
            color: #ffffff;
          }
        }
      }
    }
    .resultList {
      width: 100%;
      min-height: 500px;
      .resultList-left {
        // width: 60%;
        min-width: 740px;
        width: 100%;
        min-height: 400px;
        .resultList-left-content {
          margin: 10px auto;
        }
        .title {
          max-width: 95px;
          margin-right: 5px;
          background-color: #d30b15;
          border-left: 5px solid #d30b15;
          border-right: 5px solid #d30b15;
          font-size: 18px !important;
          color: #fff;
        }
        .headerTitle {
          font-size: 18px !important;
          color: #0042a2;
          margin-right: 0px;
        }
        .content {
          // width: 740px;
          width: 100%;
          display: flex;
        }
        .tableDiv {
          // width: 740px;
          width: 100%;
          min-height: 136px;
          border: 1px solid #ccc;
          .row {
            font-size: 18px !important;
            min-height: 40px;
            display: flex;
            border-bottom: 1px solid #ccc;
            .borderRight {
              width: 50%;
              border-right: 1px solid #ccc;
            }
          }
          .row-last {
            // min-height: 40px;
            border: none;
            padding: 10px 0 10px 10px;
            .searchChose {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              width: 90%;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
            }
          }
          .row p {
            padding: 10px;
          }
        }
        .img {
          width: 140px;
          height: 80px;
          margin-right: 20px;
          background-color: #e0e0e0;
        }
        .txt {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
        .subtilte {
          font-size: 18px !important;
          min-width: 1255px;
          margin-top: 20px;
        }
      }
      .el-pager li {
        padding: 0 10px;
        background: #fff;
        border: 1px solid #ccc;
      }
      .el-pager li.active {
        background: #eee;
        color: #666;
      }
      .el-pagination.is-background {
        .btn-next,
        .btn-prev {
          padding: 0 15px;
          background: #fff;
          border: 1px solid #ccc;
        }
      }
      .resultList-right {
        // width: 38%;
        min-width: 250px;
        // background: lightgreen;
        margin-left: 150px;
        align-self: flex-start;
        height: 400px;
        .rank {
          min-height: 300px;
          border-left: 1px solid #ccc;
          padding-left: 20px;
          margin-top: 40px;
          p {
            font-size: 18px;
            padding-bottom: 10px;
          }
          i {
            display: inline-block;
            width: 16px;
            height: 16px;
            text-align: center;
            line-height: 16px;
            color: #fff;
            font-style: normal;
            background-color: #999;
            border-radius: 3px;
            margin-top: 4px;
            margin-right: 5px;
          }
          span {
            color: #333;
          }
          .topthree {
            background-color: #d30b15;
          }
        }
      }
    }
    .details {
      .backBtn {
        margin: 10px auto;
      }
    }
  }
}
.findList {
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: $col-theme;
  }
  .el-checkbox-button.is-checked .el-checkbox-button__inner {
    background-color: red !important;
  }
  .filterItem-right span {
    color: red;
  }
  .filterItem-right .el-icon-caret-bottom {
    color: red !important;
  }
  .el-checkbox-button__inner {
    font-size: 18px;
  }
  .el-checkbox-button__inner:hover {
    color: red;
  }
  .is-checked .el-checkbox-button__inner:hover {
    color: #fff;
  }
  .visibleLable {
    .filterItem-label {
      visibility: hidden;
    }
    .visibleLable2 {
      .filterItem-label {
        visibility: visible;
      }
    }
  }
  .dialog {
    .find-tabs {
      position: relative;
      .leftTxt {
        font-size: 18px;
        color: #000;
      }
      .pos1,
      .pos2 {
        position: absolute;
        left: 20px;
      }
      .pos1 {
        top: 21px;
      }
      .pos2 {
        top: 92px;
      }
    }
  }
}
::v-deep .el-table th {
  background: none;
}
</style>
