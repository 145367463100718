<template>
  <div class="searchList">
    <div class="banner-size">
      <div class="banner-limit banner-info">
        <div class="width-limit" style="margin-top: -37px; position: relative">
          <div class="sort">
            <span class="txt">分类</span>
            <el-select v-model="menuIndex" @change="seletChange">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="input-search-bar">
            <el-input class="input-search" v-model="search" placeholder="">
            </el-input>
            <el-button class="input-search-btn" type="primary" @click="toSearch"
              >搜索
            </el-button>
          </div>
          <!-- <div class="hightLevelSearch">
            <el-button
              class="input-search-btn"
              type="primary"
              @click="highlevelSearch"
              >高级搜索
            </el-button>
          </div> -->
        </div>
      </div>
    </div>
    <!-- <div class="tabBgcolor"></div> -->
    <div class="width-limit search-result">
      <div class="s-container">
        <div class="resultTop flex-row" v-show="showDetailFlag == 'list'">
          <span style="margin-right: 70px"
            >相关结果约 <span>{{ total }}</span> 条</span
          >
          <!-- <div class="toolTitle flex-row" v-show="toolFlag">
            <div>
              <span class="boldTxt">排序：</span>
              <span
                @click="clickUnderline('about')"
                :class="{ underline: showFlag == 'about' }"
                >按相关度</span
              >
              <span
                @click="clickUnderline('date')"
                :class="{ underline: showFlag == 'date' }"
                >按日期</span
              >
            </div>
            <div>
              <span class="boldTxt">按搜索位置：</span>
              <span
                @click="clickUnderline('all')"
                :class="{ underline: showFlag == 'all' }"
                >全文</span
              >
              <span
                @click="clickUnderline('title')"
                :class="{ underline: showFlag == 'title' }"
                >标题</span
              >
            </div>
          </div> -->
          <!-- 是否展开筛选工具 -->
          <!-- <div class="tool" @click="hideTool">
            <span v-if="toolFlag"
              ><i class="el-icon-arrow-up"></i>收起工具</span
            >
            <span v-else>搜索工具</span>
          </div> -->
        </div>
        <!-- 全部 -->
        <all
          v-if="menuIndex == '1'"
          :keywords="search"
          ref="all"
          @getTotal="getTotal"
          @showTop="showTop"
        ></all>
        <!-- 政策库 -->
        <policyLibrary
          v-if="menuIndex == '2'"
          :keywords="search"
          ref="policyLibrary"
          @getTotal="getTotal"
          @showTop="showTop"
        ></policyLibrary>
        <!-- 企业办事库 -->
        <qybsLibrary
          v-if="menuIndex == '3'"
          :keywords="search"
          ref="qybsLibrary"
          @getTotal="getTotal"
        ></qybsLibrary>
        <!-- 办事场景 -->
        <!-- <businessScene
          v-if="menuIndex == '4'"
          :keywords="search"
          ref="businessScene"
          @getTotal="getTotal"
        ></businessScene> -->
        <!-- 项目库 -->
        <project
          v-if="menuIndex == '5'"
          :keywords="search"
          ref="project"
          @getTotal="getTotal"
        ></project>
        <!-- 通知公告（最新政策） -->
        <notice
          v-if="menuIndex == '6'"
          :keywords="search"
          ref="notice"
          @getTotal="getTotal"
        ></notice>
      </div>
    </div>
  </div>
</template>

<script>
// import PublicForm from "@/components/publicForm"; // 公开公示表单
// import { mapGetters } from "vuex";
import {
  all,
  policyLibrary,
  qybsLibrary,
  // businessScene,
  project,
  notice,
} from "./searchList/index.js";
export default {
  name: "searchList",
  components: {
    all,
    policyLibrary,
    qybsLibrary,
    // businessScene,
    project,
    notice,
  },
  data() {
    return {
      showDetailFlag: "list",
      total: "",
      search: "",
      activeIndex: "1",
      content: "service",
      toolFlag: true,
      showFlag: "",
      options: [
        // {
        //   value: "1",
        //   label: "全部",
        // },
        {
          value: "2",
          label: "政策库",
        },
        // {
        //   value: "3",
        //   label: "企业办事库",
        // },
        // {
        //   value: "4",
        //   label: "一件事一次办",
        // },
        {
          value: "5",
          label: "政策项目",
        },
        // {
        //   value: "6",
        //   label: "最新政策",
        // },
      ],
      menuIndex: "",
    };
  },
  created() {
    this.search = this.$route.params.keyWords;
    this.menuIndex = "2";
    if (sessionStorage.getItem("menuIndex")) {
      this.menuIndex = sessionStorage.getItem("menuIndex");
      this.activeIndex = sessionStorage.getItem("menuIndex");
    }
    if(this.$route.query.keyword) {
      this.search = this.$route.query.keyword;
      this.toSearch();
    }
  },
  mounted() {},
  methods: {
    showTop(param) {
      this.showDetailFlag = param;
    },
    seletChange(val) {

      this.menuIndex = val;
      sessionStorage.setItem("menuIndex", val);
    },
    getTotal(param) {
      this.total = param;
    },
    // handleSelect(val) {
    //   this.menuIndex = val;
    //   sessionStorage.setItem("menuIndex", val);
    //   // this.total = sessionStorage.getItem("total");
    // },
    hideTool() {
      this.toolFlag = !this.toolFlag;
    },
    toSearch() {
      switch (this.menuIndex) {
        case "1":
          this.$refs.all.getAll();
          break;
        case "2":
          this.$refs.policyLibrary.getProjectLibrary();
          break;
        case "3":
          this.$refs.qybsLibrary.getQybsLibrary();
          break;
        // case "4":
        //   this.$refs.businessScene.getProjectLibrary(this.search);
        //   break;
        case "5":
          this.$refs.project.getProject();
        case "6":
          this.$refs.notice.getNotice();
          break;
        default:
          break;
      }
    },
    highlevelSearch() {
      switch (this.menuIndex) {
        case "1":
        case "6":
          // this.$refs.all.getAll();
          this.$message({
            message: "暂无高级搜索",
            // type: "warning",
          });
          break;
        case "2":
          this.$refs.policyLibrary.searchVisible = true;
          break;
        case "3":
          this.$refs.qybsLibrary.searchVisible = true;
          break;
        // case "4":
        //   this.$refs.businessScene.getProjectLibrary(this.search);
        //   break;
        case "5":
          this.$refs.project.searchVisible = true;
        case "6":
          // this.$refs.notice.getNotice();
          break;
        default:
          break;
      }
    },
    clickUnderline(val) {
      this.showFlag = val;
    },
    highlight(val) {
      // const Reg = new RegExp(this.search, 'ig')
      // val = val + "";
      // if (val.indexOf(this.search) !== -1 && this.search !== "") {
      //   return val.replace(
      //     Reg,
      //     '<font color="red">' + this.search + "</font>"
      //   );
      // } else {
      //   return val;
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/com/theme.scss";
@import "@/styles/com/flex.scss";
.seperation {
  height: 20px;
  background-color: #fff;
}
.searchList {
  .banner-size {
    width: 100%;
    height: 200px;
    background: url("~@/assets/deyangImg/zcjsqBanber.png");
  }
  .banner-info {
    height: 100%;
    display: flex;
    align-items: center;
    .sort {
      width: 160px;
      height: 50px;
      position: relative;
      margin-left: 72px;
      padding-top: 30px;
      ::v-deep .el-select .el-input__inner {
        height: 55px !important;
        font-size: 18px;
      }
      ::v-deep .el-select-dropdown__item {
        font-size: 18px !important;
      }
      .txt {
        font-size: 22px;
        font-weight: bold;
        position: absolute;
        top: 42px;
        left: -70px;
      }
    }
    .input-search-bar {
      height: 55px;
      width: 50%;
      margin: auto;
      margin-top: -20px;
      border-radius: 4px;
      background: #ffffff;

      /* 输入框头部 */
      .input-search-pre {
        width: 120px !important;
        color: #404040;
        font-size: 16px;
        background: $color-fff;
        .el-input__inner {
          text-align: center !important;
          border: none !important;
          border-radius: 0 !important;
          color: #333;
          background-color: #ffffff !important;
        }
      }

      /* 输入框内容 */
      .input-search {
        flex: 1;
        .el-input__inner {
          border: none !important;
          border-radius: 0 !important;
          background-color: #ffffff !important;
          font-size: 18px;
        }
        input::-webkit-input-placeholder {
          color: #999999;
        }
      }
      /* 输入框按钮 */
      .input-search-btn {
        background: #03b1ff;
        height: 100%;
        width: 100px;
        border-radius: 0 !important;
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
        border: 2px solid #b6a99c !important;
        text-align: center;
        font-size: $font-size18;
        color: $color-fff;
        cursor: pointer;
        .el-button {
          font-weight: bold !important;
        }
      }
    }
    .hightLevelSearch {
      width: 120px;
      height: 55px;
      // background-color: coral;
      position: absolute;
      top: 30px;
      right: 138px;
      .input-search-btn {
        background: #03b1ff;
        height: 100%;
        width: 100%;
        border-radius:4px;
        border: 2px solid #b6a99c !important;
        // text-align: center;
        font-size: $font-size18;
        color: $color-fff;
        cursor: pointer;
        .el-button {
          font-weight: bold !important;
        }
      }
    }
  }
  .search-bar {
    margin: 40px;
    justify-content: flex-start;
    .s-input {
      width: 40% !important;
    }
  }
  // .tabBgcolor {
  //   width: 100%;
  //   height: 60px;
  //   background-color: #df1d09;
  //   position: absolute;
  //   top: 248px;
  // }
  .tool {
    position: absolute;
    top: 0;
    right: -5px;
    cursor: pointer;
  }
  .search-result {
    min-width: 1440px;
    margin: 0 auto;
    font-size: 16px;
  }
  .s-container {
    width: 100%;
    min-height: 500px;
    padding: 20px 0;
    .resultTop {
      font-size: 18px;
    }
    .el-menu-demo {
      width: 1440px;
      margin: 0 auto;
      margin-top: -71px;
      background-color: rgba(255, 255, 255, 0.3);
    }
    .el-menu-item {
      padding: 0 50px !important;
      height: 50px;
      line-height: 50px;
      font-size: 22px;
      font-family: "Microsoft Yahei";
    }
    .el-menu-item:hover {
      color: #df1d09 !important;
    }
    .el-menu--horizontal > .el-menu-item.is-active {
      color: #df1d09;
      background-color: #fff !important ;
      border-bottom: 2px solid #fff;
    }
  }
}
</style>
