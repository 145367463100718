<template>
  <div>
    <!-- 搜索结果列表 -->
    <div class="resultList flex-row-between">
      <div class="resultList-left" v-show="showDetailFlag == 'list'">
        <el-table
          :data="tableData"
          style="width: 100%"
          @row-click="toRelativePage"
        >
          <el-table-column label="" :show-header="false">
            <template slot-scope="scope">
              <!-- 政策库的 -->
              <div
                class="resultList-left-content"
                v-show="scope.row.dataType == 'sq-policy'"
              >
                <div style="margin-bottom: 10px">
                  <span class="title">{{ scope.row.fbjg }}</span>
                  <span class="headerTitle" v-html="scope.row.name"></span>
                </div>
                <div style="width: 100%">
                  <div class="tableDiv">
                    <div class="row">
                      <p class="borderRight">
                        <span class="boldTxt">发文字号：</span>
                        <span v-html="scope.row.fwzh"></span>
                      </p>
                      <p>
                        <span class="boldTxt">发布机构：</span>
                        <span v-html="scope.row.fbjg"></span>
                      </p>
                    </div>
                    <div class="row">
                      <p style="width: 100%; border-right: none">
                        <span class="boldTxt">发布日期：</span>
                        <span v-html="scope.row.publictime"></span>
                      </p>
                    </div>
                    <div class="row row-last">
                      <span class="boldTxt">搜索命中：</span>
                      <span
                        class="searchChose"
                        v-html="scope.row.content"
                      ></span>
                    </div>
                  </div>
                  <div class="subtilte flex-row-between">
                    <div>
                      <span v-html="scope.row.deptName"></span>-<span
                        v-html="scope.row.publictime"
                      ></span>
                    </div>
                    <!-- <span>相似信息 <span style="color: red">1</span> 条</span> -->
                  </div>
                </div>
              </div>
              <!-- 企业办事库的 -->
              <div
                class="resultList-left-content"
                v-show="scope.row.dataType == 'pre-service'"
              >
                <div style="margin-bottom: 10px">
                  <span class="title">事项名称</span>
                  <span class="headerTitle" v-html="scope.row.title"></span>
                </div>
                <div style="width: 100%">
                  <div class="tableDiv">
                    <div class="row">
                      <p class="borderRight">
                        <span class="boldTxt">事项类型：</span>
                        <span v-html="scope.row.serviceType"></span>
                      </p>
                      <p>
                        <span class="boldTxt">事项名称：</span>
                        <span v-html="scope.row.scenenName"></span>
                      </p>
                    </div>
                    <div class="row">
                      <p class="borderRight">
                        <span class="boldTxt">实施主体：</span>
                        <span v-html="scope.row.deptName"></span>
                      </p>
                      <p>
                        <span class="boldTxt">行使层级：</span>
                        <span v-html="scope.row.perFormLevel"></span>
                      </p>
                    </div>
                    <div class="row">
                      <p>
                        <span class="boldTxt">服务对象：</span>
                        <span v-html="scope.row.forUser"></span>
                      </p>
                    </div>
                    <div class="row row-last">
                      <span class="boldTxt">行使内容:</span>
                      <span v-html="scope.row.perFormContent"></span>
                      <!-- <span class="boldTxt">形式内容</span> -->
                    </div>
                  </div>
                  <div class="subtilte flex-row-between">
                    <span>{{ scope.row.createTime }}</span>
                  </div>
                </div>
              </div>
              <!-- 政策项目  -->
              <div
                class="resultList-left-content"
                v-show="scope.row.dataType == 'project'"
              >
                <div style="margin-bottom: 10px">
                  <span class="title">政策项目</span>
                  <span class="headerTitle" v-html="scope.row.name"></span>
                </div>
                <div style="width: 100%">
                  <div class="tableDiv">
                    <div class="row">
                      <p>
                        <span class="boldTxt">项目名称：</span>
                        <span v-html="scope.row.name"></span>
                      </p>
                    </div>
                    <div class="row">
                      <p class="borderRight">
                        <span class="boldTxt">扶持金额：</span>
                        <span v-html="scope.row.capital"></span>
                      </p>
                      <p>
                        <span class="boldTxt">受理部门：</span>
                        <span v-html="scope.row.deptName"></span>
                      </p>
                    </div>
                    <div class="row">
                      <p>
                        <span class="boldTxt">申报日期：</span>
                        <span >{{scope.row.startTime.split(" ")[0]}}</span>
                        ~
                        <span >{{scope.row.endTime.split(" ")[0]}}</span>
                      </p>
                    </div>
                    <div class="row row-last">
                      <span class="boldTxt">扶持内容：</span>
                      <span v-html="scope.row.supportStandard"></span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 通知公告 notice -->
              <div
                class="resultList-left-content"
                v-show="scope.row.dataType == 'notice'"
              >
                <div style="margin-bottom: 10px">
                  <span class="title">最新政策</span>
                  <span class="headerTitle" v-html="scope.row.name"></span>
                </div>
                <div class="content">
                  <div>
                    <div class="txt" v-html="scope.row.content"></div>
                    <div class="subtilte flex-row-between">
                      <span v-html="scope.row.policyPubDate"></span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          hide-on-single-page
          style="margin-top: 10px"
          background
          :page-size="pageSize"
          layout="prev, pager, next,total, jumper"
          prev-text="上一页"
          next-text="下一页"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="CurrentChange"
        >
        </el-pagination>
      </div>
      <div class="details" v-show="showDetailFlag == 'detail'">
        <div class="backBtn">
          <el-button type="primary" @click="toPolicy">返回</el-button>
        </div>
        <div v-html="detailContent"></div>
      </div>
      <!-- <div class="resultList-right">
            <div class="rank">
              <p>搜索排行</p>
              <div><i class="topthree">1</i><span>“两区”建设</span></div>
              <div><i class="topthree">2</i><span>市民对话一把手</span></div>
              <div><i class="topthree">3</i><span>防汛</span></div>
              <div><i>4</i><span>中招</span></div>
              <div><i>5</i><span>高招</span></div>
              <div><i>6</i><span>疫情防控</span></div>
            </div>
          </div> -->
    </div>
  </div>
</template>

<script>
const tableData = [];
export default {
  name: "all",
  props: {
    highlight: {
      type: Function,
    },
    keywords: {
      type: String,
    },
  },
  data() {
    return {
      showDetailFlag: "list",
      detailContent: "", //政策详情内容
      tableData,
      total: 0,
      pageSize: 5,
    };
  },
  computed: {},
  created() {
    this.getAll();
  },
  mounted() {},
  methods: {
    toRelativePage(row) {
      switch (row.dataType) {
        case "sq-policy":
          // 政策库
          // 展示政策详情
          this.showDetailFlag = "detail";
          this.detailContent = row.content2;
          this.$emit("showTop", "detail");
          break;
        case "pre-service":
          // 企业办事库
          sessionStorage.setItem("matterRow", JSON.stringify(row));
          sessionStorage.setItem("matterChooseType", "1");
          // let { href1 } = this.$router.resolve({
          //   path: "/serviceGuide",
          // });
          // window.open(href1, "_blank");
          this.$router.push({ name: "serviceGuide" });
          break;
        case "project":
          // 政策项目
          this.$store.commit("updateCurrentGuideId", row.id);
          this.$router.push({
            path: "/guideSuqiu",
            query: {
              name: row.name,
              itemType: row.itemtype,
              isAdded: row.isAdded,
            },
          });
          break;
        case "notice":
          // 通知公告
          let { href } = this.$router.resolve({
            path: "/notice/detail/" + row.id,
          });
          sessionStorage.setItem("linkType", true);
          window.open(href, "_blank");
          break;

        default:
          break;
      }
    },
    toPolicy() {
      this.showDetailFlag = "list";
      this.$emit("showTop", "list");
    },
    getAll() {
      let url = "/elastic/search/doc";
      let params = {
        pageNum: 1,
        pageSize: this.pageSize,
        keyWords: this.keywords,
      };
      let that = this;
      that.$httpApi
        .post(url, params)
        .then((res) => {
          that.total = res.total;
          that.$emit("getTotal", res.total);
          that.tableData = res.rows;
        })
        .catch((e) => {});
    },
    handleSizeChange(val) {},
    // 当前页改变时
    CurrentChange(pageNum) {
      let pageSize = this.pageSize;
      let url = "/elastic/search/doc";
      let params = {
        pageNum: pageNum,
        pageSize: pageSize,
        keyWords: this.keywords,
      };
      let that = this;
      that.$httpApi
        .post(url, params)
        .then((res) => {
          that.total = res.total;
          that.tableData = res.rows;
          that.$emit("getTotal", res.total);
        })
        .catch((e) => {});
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/com/theme.scss";
@import "@/styles/com/flex.scss";
.filter-params-inner {
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ::v-deep .el-tag {
    margin-right: 5px;
    color: $col-theme;

    .el-tag__close {
      color: $col-theme;

      &:hover {
        color: #ffffff;
      }
    }
  }
}
.resultList {
  min-height: 500px;
  .resultList-left {
    // width: 60%;
    min-width: 740px;
    width: 100%;
    min-height: 400px;
    .el-table td {
      border-bottom: none;
    }
    .resultList-left-content {
      margin: 10px auto;
    }
    .title {
      max-width: 95px;
      margin-right: 5px;
      background-color: #d30b15;
      border-left: 5px solid #d30b15;
      border-right: 5px solid #d30b15;
      font-size: 18px !important;
      color: #fff;
    }
    .headerTitle {
      font-size: 18px !important;
      color: #0042a2;
      margin-right: 0px;
    }
    .content {
      // width: 740px;
      width: 100%;
      display: flex;
    }
    .tableDiv {
      width: 100%;
      min-height: 136px;
      border: 1px solid #ccc;
      .row {
        min-height: 40px;
        display: flex;
        border-bottom: 1px solid #ccc;
        .borderRight {
          width: 50%;
          border-right: 1px solid #ccc;
        }
      }
      .boldTxt {
        font-size: 18px !important;
        color: #000;
        font-weight: 700;
      }
      .row-last {
        // min-height: 40px;
        border: none;
        padding: 10px;
        font-size: 18px !important;
      }
      // .row p:nth-child(1) {
      //   width: 50%;
      //   border-right: 1px solid #ccc;
      // }
      .row p {
        padding: 10px;
      }
    }
    .img {
      width: 140px;
      height: 80px;
      margin-right: 20px;
      // background-color: #e0e0e0;
    }
    .txt {
      font-size: 18px !important;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      /*控制在2行*/
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .subtilte {
      font-size: 18px !important;
      min-width: 1255px;
      width: 100%;
      margin-top: 20px;
    }
  }
  .details {
    .backBtn {
      margin: 10px auto;
    }
  }
  .el-pager li {
    padding: 0 10px;
    background: #fff;
    border: 1px solid #ccc;
  }
  .el-pager li.active {
    background: #eee;
    color: #666;
  }
  .el-pagination.is-background {
    .btn-next,
    .btn-prev {
      padding: 0 15px;
      background: #fff;
      border: 1px solid #ccc;
    }
  }
  .resultList-right {
    // width: 38%;
    min-width: 250px;
    // background: lightgreen;
    margin-left: 150px;
    align-self: flex-start;
    height: 400px;
    .rank {
      min-height: 300px;
      border-left: 1px solid #ccc;
      padding-left: 20px;
      margin-top: 40px;
      p {
        font-size: 18px;
        padding-bottom: 10px;
      }
      i {
        display: inline-block;
        width: 16px;
        height: 16px;
        text-align: center;
        line-height: 16px;
        color: #fff;
        font-style: normal;
        background-color: #999;
        border-radius: 3px;
        margin-top: 4px;
        margin-right: 5px;
      }
      span {
        color: #333;
      }
      .topthree {
        background-color: #d30b15;
      }
    }
  }
}
::v-deep .el-table th {
  background: none;
}
</style>
