<template>
  <div>
       <!-- 搜索结果列表 -->
    <div class="resultList flex-row-between">
      <div class="resultList-left">
         <el-table :data="tableData" style="width: 100%" @row-click="toNotice">
          <el-table-column label="" :show-header="false">
            <template slot-scope="scope">
               <div class="resultList-left-content">
                <div style="margin-bottom: 10px">
                  <span class="title">最新政策</span>
                  <span
                   class="headerTitle"
                    v-html="scope.row.name"
                  ></span>
                </div>
                <div class="content">
                  <div>
                    <div class="txt" v-html="scope.row.content"></div>
                    <div class="subtilte flex-row-between">
                      <span v-html="scope.row.policyPubDate"></span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          hide-on-single-page
          style="margin-top: 10px"
          background
          :page-size="pageSize"
          layout="prev, pager, next,total, jumper"
          prev-text="上一页"
          next-text="下一页"
          :total="total"
          @current-change="CurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
const tableData  = [];
export default {
  name: "notice",
  props: {
    highlight: {
      type: Function,
    },
    keywords: {
      type: String,
    },
  },
  data() {
    return {
      tableData,
      total: 0,
      pageSize: 5,
    };
  },
  created() {
    this.getNotice()
  },
  mounted() {
  },
  methods: {
   // 当前页改变时
    CurrentChange(pageNum) {
      let pageSize = this.pageSize;
      let url = "/elastic/search/doc";
      let params = {
        pageNum: pageNum,
        pageSize: pageSize,
        keyWords: this.keywords,
      };
      let that = this;
      that.$httpApi
        .post(url, params)
        .then((res) => {
          that.total = res.total;
          that.tableData = res.rows;
          that.$emit("getTotal",res.total)
        })
        .catch((e) => {});
    },
    getNotice() {
      let url = "/elastic/search/notice";
      let params = {
        pageNum: 1,
        pageSize: 10,
        keyWords: this.keywords,
      };
      let that = this;
      that.$httpApi
        .post(url, params)
        .then((res) => {
          that.total = res.total;
          that.$emit("getTotal",res.total)
          that.tableData = res.rows;
        })
        .catch((e) => {});
    },
    // 跳通知公告详情
    toNotice(row){
      let that = this;
      let { href } = that.$router.resolve({
        path: "/notice/detail/" + row.id,
        // query:{
        //   linkType:true,
        // }
      });
      sessionStorage.setItem("linkType",true)
      window.open(href, "_blank");
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/com/theme.scss";
@import "@/styles/com/flex.scss";
.seperation {
  height: 20px;
  background-color: #fff;
}
.searchList {
  .banner-info {
    height: 100%;
    display: flex;
    align-items: center;
    .input-search-bar {
      height: 55px;
      width: 50%;
      margin-bottom: 10px;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      background: #ffffff;
      margin-left: 74px;

      /* 输入框头部 */
      .input-search-pre {
        width: 120px !important;
        color: #404040;
        font-size: 16px;
        background: $color-fff;
        .el-input__inner {
          text-align: center !important;
          border: none !important;
          border-radius: 0 !important;
          color: #333;
          background-color: #ffffff !important;
        }
      }

      /* 输入框内容 */
      .input-search {
        flex: 1;
        .el-input__inner {
          border: none !important;
          border-radius: 0 !important;
          background-color: #ffffff !important;
        }
        input::-webkit-input-placeholder {
          color: #999999;
        }
      }
      /* 输入框按钮 */
      .input-search-btn {
        background: #e60000;
        height: 100%;
        width: 100px;
        border-radius: 0 !important;
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
        border: 2px solid #b6a99c !important;
        text-align: center;
        font-size: $font-size18;
        color: $color-fff;
        cursor: pointer;
        .el-button {
          font-weight: bold !important;
        }
      }
    }
  }
  .search-bar {
    margin: 40px;
    justify-content: flex-start;
    .s-input {
      width: 40% !important;
    }
  }
  .tabBgcolor {
    width: 100%;
    height: 60px;
    background-color: #df1d09;
    position: absolute;
    top: 248px;
  }
  .tool {
    position: absolute;
    top: 0;
    right: -5px;
    cursor: pointer;
  }
  .search-result {
    width: 1000px;
  }
  .s-container {
    width: 100%;
    min-height: 500px;
    padding: 20px 0;
    .el-menu-demo {
      margin-top: -20px;
    }
    .el-menu--horizontal > .el-menu-item.is-active {
      color: #fff;
      border-bottom: 2px solid #fff;
    }
    .resultTop {
      position: relative;
      cursor: pointer;
      margin-top: 30px;
      .underline {
        text-decoration: underline;
      }
      .toolTitle div {
        margin-right: 15px;
        span {
          margin-right: 15px;
        }
        .boldTxt {
          color: #000;
          font-weight: 700;
        }
      }
      .el-input__inner {
        width: 50%;
        border: none;
        color: #333;
        padding-right: 0;
      }
      .el-input__suffix {
        right: 60px;
      }
      input::-webkit-input-placeholder {
        color: #333;
      }
    }
    .find-list-container {
      margin-top: 14px;
      .el-input {
        input {
          background-color: #ffffff;
          border-radius: 0px !important;
        }
      }
      .el-input-group__append {
        button {
          background-color: rgb(75, 145, 233);
        }
      }
      .el-row {
        width: 360px;
        display: flex;

        .el-button {
          border: 0px;
          border-radius: 0px;
          width: 90px;

          .el-icon-bottom {
            margin-left: 15px;
          }

          &:hover {
            background-color: #2d8cf0;
          }
        }
      }
    }
    .filter-params-inner {
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ::v-deep .el-tag {
    margin-right: 5px;
    color: $col-theme;

    .el-tag__close {
      color: $col-theme;

      &:hover {
        color: #ffffff;
      }
    }
  }
}
    .resultList {
      width: 100%;
      min-height: 500px;
      .resultList-left {
        // width: 60%;
        min-width: 740px;
        width: 100%;
        min-height: 400px;
        .resultList-left-content {
          margin: 10px auto;
        }
        .title {
          max-width: 95px;
          margin-right: 5px;
          background-color: #d30b15;
          border-left: 5px solid #d30b15;
          border-right: 5px solid #d30b15;
          font-size: 18px;
          color: #fff;
        }
         .headerTitle{
          font-size: 18px;
          color: #0042a2;
          margin-right: 0px;
        }
        .content {
          // width: 740px;
          width: 100%;
          display: flex;
        }
        .tableDiv {
          // width: 740px;
          width: 100%;
          min-height: 136px;
          border: 1px solid #ccc;
          .row {
            min-height: 40px;
            display: flex;
            border-bottom: 1px solid #ccc;
            .borderRight {
            width: 50%;
            border-right: 1px solid #ccc;
            }
          }
          .row-last {
            // min-height: 40px;
            border: none;
            padding: 10px;
          }
          // .row p:nth-child(1) {
          //   width: 50%;
          //   border-right: 1px solid #ccc;
          // }
          .row p {
            padding: 10px;
          }
        }
        .img {
          width: 140px;
          height: 80px;
          margin-right: 20px;
          background-color: #e0e0e0;
        }
        .txt {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
        .subtilte {
          min-width: 1255px;
          margin-top: 20px;
        }
      }
      .el-pager li {
        padding: 0 10px;
        background: #fff;
        border: 1px solid #ccc;
      }
      .el-pager li.active {
        background: #eee;
        color: #666;
      }
      .el-pagination.is-background {
        .btn-next,
        .btn-prev {
          padding: 0 15px;
          background: #fff;
          border: 1px solid #ccc;
        }
      }
      .resultList-right {
        // width: 38%;
        min-width: 250px;
        // background: lightgreen;
        margin-left: 150px;
        align-self: flex-start;
        height: 400px;
        .rank {
          min-height: 300px;
          border-left: 1px solid #ccc;
          padding-left: 20px;
          margin-top: 40px;
          p {
            font-size: 18px;
            padding-bottom: 10px;
          }
          i {
            display: inline-block;
            width: 16px;
            height: 16px;
            text-align: center;
            line-height: 16px;
            color: #fff;
            font-style: normal;
            background-color: #999;
            border-radius: 3px;
            margin-top: 4px;
            margin-right: 5px;
          }
          span {
            color: #333;
          }
          .topthree {
            background-color: #d30b15;
          }
        }
      }
    }
  }
}
.findList {
    .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: $col-theme;
    }
    .el-checkbox-button.is-checked .el-checkbox-button__inner{
      background-color: red !important;
    }
    .filterItem-right span{
      color: red;
    }
    .filterItem-right .el-icon-caret-bottom{
      color: red !important;
    }
    .el-checkbox-button__inner:hover{
      color: red;
    }
    .is-checked .el-checkbox-button__inner:hover{
      color: #fff;
    }

}
</style>
