<template>
  <div>
    <!-- 搜索条件 -->
    <div class="def-container find-list-container">
      <div class="findList" ref="findList">
        <!--筛选-->
        <el-dialog
          title="高级搜索条件"
          :visible.sync="searchVisible"
          width="50%"
          class="dialog"
        >
          <FilterGroup @change="changeSearch">
            <FilterRadio
              v-model="searchInfo.data.deptcode"
              label="发布部门"
              :list="searchInfo.deptList"
              valueKey="deptid"
              labelKey="deptname"
              fold
            ></FilterRadio>
            <FilterRadio
              v-model="searchInfo.data.xmlb"
              label="项目类别"
              :list="getDictList('sq_policy_xmlb')"
              valueKey="key"
              fold
            ></FilterRadio>
            <FilterRadio
              v-model="searchInfo.data.cylb"
              label="产业类别"
              :list="cpu_cylbList"
              :needAll="false"
              valueKey="key"
              fold
            ></FilterRadio>
            <FilterRadio
              v-model="searchInfo.data.zcfs"
              label="支持方式"
              :list="getDictList('sq_policy_zjlx')"
              valueKey="key"
            ></FilterRadio>
            <FilterRadio
              v-show="searchInfo.data.zcfs == '1'"
              class="searchItem-amount"
              v-model="searchInfo.data.temp_amount"
              label="资金限额"
              :list="searchInfo.amountList"
            >
              <template v-slot:append>
                <div
                  v-show="searchInfo.data.temp_amount == '4'"
                  class="searchItem-amount-custom"
                >
                  <FormInput
                    v-model.number="searchInfo.data.zzxeleft"
                    size="small"
                    @change="changeSearch"
                  ></FormInput
                  >万元
                  <span style="margin: 0 5px">~</span>
                  <FormInput
                    v-model.number="searchInfo.data.zzxeright"
                    size="small"
                    @change="changeSearch"
                  ></FormInput
                  >万元
                </div>
              </template>
            </FilterRadio>
          </FilterGroup>
          <span slot="footer" class="dialog-footer">
            <el-button @click="searchVisible = false">取 消</el-button>
            <el-button type="primary" @click="confirmClose"
              >确 定</el-button
            >
          </span>
        </el-dialog>
        <div class="find-tabs" v-show="cpu_paramsList.length">
          <FilterGroup @change="changeSearch" style="min-height:50px;margin-bottom:-20px">
            <FilterRadio label="已选择" :needAll="false">
              <div class="filter-params-inner">
                <div class="FilterRadio-params-left">
                  <span v-if="!cpu_paramsList.length">无</span>
                  <a
                    v-else
                    class="pad-rl-1 search-params-clean"
                    @click="cleanParams"
                    >清空</a
                  >
                  <el-tag
                    v-for="(item, index) in cpu_paramsList"
                    :key="index"
                    closable
                    @close="closeParams(item)"
                  >
                    {{ item.label }}
                  </el-tag>
                </div>
                <div class="FilterRadio-params-right">
                  <el-radio-group
                    v-model="searchInfo.type"
                    size="small"
                    @change="changeSearchType"
                  >
                  </el-radio-group>
                </div>
              </div>
            </FilterRadio>
          </FilterGroup>
        </div>
      </div>
    </div>
    <!-- 搜索结果列表 -->
    <div class="resultList flex-row-between">
      <div class="resultList-left">
        <el-table
          :data="tableData"
          style="width: 100%"
          @row-click="toGuideSuqiu"
        >
          <el-table-column label="" :show-header="false">
            <template slot-scope="scope">
              <div class="resultList-left-content">
                <div style="margin-bottom: 10px">
                  <span class="title">政策项目</span>
                  <span
                    class="headerTitle"
                    v-html="scope.row.name"
                  ></span>
                </div>
                <div style="width: 100%">
                  <div class="tableDiv">
                    <div class="row">
                      <p>
                        <span class="boldTxt">项目名称：</span>
                        <span v-html="scope.row.name"></span>
                      </p>
                    </div>
                    <div class="row">
                      <p class="borderRight">
                        <span class="boldTxt">扶持金额：</span>
                        <span v-html="scope.row.capital+'万元'"></span>
                      </p>
                      <p>
                        <span class="boldTxt">受理部门：</span>
                        <span v-html="scope.row.deptName"></span>
                      </p>
                    </div>
                    <div class="row">
                      <p>
                        <span class="boldTxt">申报日期：</span>
                        <span>{{scope.row.startTime.split(" ")[0]}}</span>
                        ~
                        <span>{{scope.row.endTime.split(" ")[0]}}</span>
                      </p>
                    </div>
                    <div class="row row-last" :title="scope.row.supportStandard">
                      <span class="boldTxt">扶持内容：</span>
                      <span v-html="scope.row.supportStandard.legnth>=60?scope.row.supportStandard:scope.row.supportStandard.slice(0,60)+'...'"></span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          hide-on-single-page
          style="margin-top: 10px"
          background
          :page-size="pageSize"
          layout="prev, pager, next,total, jumper"
          prev-text="上一页"
          next-text="下一页"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="CurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// import PublicForm from "@/components/publicForm"; // 公开公示表单
import { mapGetters } from "vuex";
import {
  FilterGroup,
  //   FilterCheckBox,
  FilterRadio,
} from "../../policyLibrary/components/filter/index.js";
import { FormInput } from "@/components/form/index.js";
const tableData = [];
export default {
  name: "project",
  components: {
    FilterGroup,
    // FilterCheckBox,
    FilterRadio,
    FormInput,
  },
  props: {
    highlight: {
      type: Function,
    },
    keywords: {
      type: String || Number,
    },
  },
  data() {
    return {
      searchVisible: false, //搜索条件弹框
      tableData,
      total: 0,
      pageSize: 5,
      dialogVisible: false, //登录提示弹窗
      showList: [],
      activeTab: "0", // 3大类 1 审批 2 资金 3 诉求
      collectList: [],
      // 搜索条件
      searchInfo: {
        data: {
          xmlb: "", // 项目类别
          cylb: "", // 产业类别
          deptcode: "", // 发布部门
          zcfs: "", // 支持方式
          temp_amount: "", // 资金限额
          zzxeleft: "", // 起始资金限额
          zzxeright: "", // 结束资金限额
          name: "", // 事项名称
        },
        oldParams: {},
        deptList: [], // 职能部门列表
        amountList: [
          { value: "0", label: "1000万及以上" },
          { value: "1", label: "500万~1000万" },
          { value: "2", label: "100万~500万" },
          { value: "3", label: "100万及以下" },
          // { value: '4', label: '自定义' },
        ],
        type: "0", // 搜索类型{'0':传统列表, '1':数字化列表}
      },
      dataLoading: false, // dataLoading状态位
      // 分页
      pageInfo: {
        page: 1,
        size: 5,
        total: 0,
      },
      projectName: "", //政策项目名称
      deptName: [], //发布部门
      projectType: [], //项目类别
      industryType: [], //产业类别
      supportWay: [], //支持方式
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
      getDictList: "dictionary/getDictList",
      getDictLabel: "dictionary/getDictLabel",
    }),
    /**
     * @description: 产业类别列表
     */
    cpu_cylbList() {
      let list = JSON.parse(JSON.stringify(this.getDictList("sys_enter_type")));
      if (!!list.length) {
        list[0].key = "";
        list[0].label = "不限";
      }

      return list;
    },
    /**
     * @description: 已选择的搜索条件列表
     * @return {Array}
     */
    cpu_paramsList() {
      let list = [];
      const params = this.searchInfo.oldParams;
      // console.log('oldParams:', params)
      if (params.deptcode) {
        list.push({
          type: "deptcode",
          value: params.deptcode,
          label: this.searchInfo.deptList.find(
            (cv) => cv.deptid === params.deptcode
          ).deptname,
        });
      }
      if (params.xmlb) {
        list.push({
          type: "xmlb",
          value: params.xmlb,
          label: this.getDictList("sq_policy_xmlb").find(
            (cv) => cv.key === params.xmlb
          ).label,
        });
      }
      if (params.cylb) {
        list.push({
          type: "cylb",
          value: params.cylb,
          label: this.cpu_cylbList.find((cv) => cv.key === params.cylb).label,
        });
      }
      if (params.zcfs) {
        list.push({
          type: "zcfs",
          value: params.zcfs,
          label: this.getDictList("sq_policy_zjlx").find(
            (cv) => cv.key === params.zcfs
          )?.label,
        });
      }
      if (params.temp_amount) {
        let item = {
          type: "temp_amount",
          value: params.temp_amount,
          label: "",
        };
        switch (params.temp_amount) {
          case "0":
          case "1":
          case "2":
          case "3":
            item.label = this.searchInfo.amountList.find(
              (cv) => cv.value == params.temp_amount
            ).label;
            break;
          default:
            item.label = `${params.zzxeleft || "--"}万元 ~ ${
              params.zzxeright || "--"
            }万元`;
            break;
        }
        list.push(item);
      }
      // 传参
      this.cleanProjectParam();
      list.forEach((item) => {
        switch (item.type) {
          case "deptcode":
            this.deptName.push(item.label);
            break;
          case "xmlb":
            this.projectType.push(item.label);
            break;
          case "cylb":
            this.industryType.push(item.label);
            break;
          case "zcfs":
            this.supportWay.push(item.label);
            break;
          default:
            break;
        }
      });
      return list;
    },
    /**
     * @description: 表格 办理对象 显示文本
     * @param {String} bldx 办理对象
     * @return {String}
     */
    cpu_blzcText() {
      return function (bldx) {
        if (!bldx) return "";

        return this.getDictLabel("permit_sertype", bldx);
      };
    },
    /**
     * @description: 表格 企业注册类型 显示文本
     * @param {String} qyzclx 企业注册类型
     * @return {String}
     */
    cpu_qyzclxText() {
      return function (qyzclx) {
        if (!qyzclx) return "";

        let temp_qyzclx = qyzclx
          .split(",")
          .map((cv) => this.getDictLabel("sys_enterprise_registertype", cv))
          .join(",");
        return temp_qyzclx;
      };
    },
    cpu_hylbText() {
      return function (hylb) {
        if (!hylb) return "--";

        return hylb
          .split(",")
          .map((cv) => this.getDictLabel("sys_category", cv))
          .join(", ");
      };
    },
    /**
     * @description: 荣誉信息/资质认证要求 的显示文本
     * @param {String} ryxx 荣誉信息
     * @param {String} zzrzyq 资质认证要求
     * @return {String}
     */
    cpu_ryzzText() {
      return function (ryxx, zzrzyq) {
        if (ryxx && zzrzyq) {
          return `${ryxx} / ${zzrzyq}`;
        } else if (!ryxx && !zzrzyq) {
          return "";
        }
        return ryxx || zzrzyq;
      };
    },
    cpu_noText() {
      return function (val) {
        return val || "--";
      };
    },
  },
  methods: {
    confirmClose(){
      this.searchVisible = false;
      this.getProject();
    },
    // getProject传参清空
    cleanProjectParam() {
      this.deptName = [];
      this.projectType = [];
      this.industryType = [];
      this.supportWay = [];
    },
    /**
     * @description 清空已选搜索项
     */
    cleanParams() {
      this.cleanProjectParam();
      for (const k of Object.keys(this.searchInfo.data)) {
        this.searchInfo.data[k] = "";
      }
      this.searchInfo.oldParams = JSON.parse(
        JSON.stringify(this.searchInfo.data)
      );

      this.showList = [];
      this.pageInfo.total = 0;
      this.getListData(1, this.pageInfo.size);
    },
    /**
     * @description: 删除已选搜索项
     * @param {Object} tag
     */
    closeParams(tag) {
      switch (tag.type) {
        case "temp_amount":
          this.searchInfo.data.temp_amount = "";
          this.searchInfo.data.zzxeleft = "";
          this.searchInfo.data.zzxeright = "";
          break;
        default:
          this.searchInfo.data[tag.type] = "";
          break;
      }
      this.searchInfo.oldParams = JSON.parse(
        JSON.stringify(this.searchInfo.data)
      );

      this.showList = [];
      this.pageInfo.total = 0;
      this.getListData(1, this.pageInfo.size);
    },
    /**
     * @description: 修改搜索类型
     * @param {String} val 值
     */
    changeSearchType(val) {
      this.searchInfo.oldParams = JSON.parse(
        JSON.stringify(this.searchInfo.data)
      );

      this.showList = [];
      this.pageInfo.total = 0;
      this.getListData(1, this.pageInfo.size);
    },
    /**
     * @description: 修改搜索条件
     */
    changeSearch(label, value) {
      // 特殊情况不需要触发搜索
      //     资金限额自定义时
      this.searchInfo.data;
      switch (label) {
        case "支持方式":
          if (value !== "1") {
            this.$set(this.searchInfo.data, "temp_amount", "");
            this.searchInfo.data.zzxeleft = "";
            this.searchInfo.data.zzxeright = "";
          }
          break;
        case "资金限额":
          if (value == "4") return;
          this.searchInfo.data.zzxeleft = "";
          this.searchInfo.data.zzxeright = "";
          break;
        default:
          break;
      }
      this.searchInfo.oldParams = JSON.parse(
        JSON.stringify(this.searchInfo.data)
      );

      this.showList = [];
      this.pageInfo.total = 0;
      this.getListData(1, this.pageInfo.size);
    },
    /**
     * @description 查询职能部门列表
     */
    getDeptList() {
      let url = "/dev-api/sqzdManager/data/find/sq_policy_project_dept_list";
      let params = {};
      this.$httpApi
        .post(url, params)
        .then((res) => {
          // console.log('getDeptList:', res)
          if (res.data && !!res.data.length) {
            this.searchInfo.deptList = res.data || [];
          }
        })
        .catch((err) => {
          console.log("getDeptList error:", res);
        });
    },
    /**
     * @description: 查询政策列表
     */
    getListData(page, size) {
      /**
       * 20210520
       * 查询区分了 传统列表/数字化列表
       * 为了可控，用switch分开处理逻辑
       *
       * 20210615
       * 资助标准列表，如果按资助标准的维度来查询，会带来序号的问题
       * 逻辑改为，先调传统政策项目列表，再传批量id查询资助标准
       */
      this.pageInfo.page = page ?? this.pageInfo.page;
      this.pageInfo.size = size ?? this.pageInfo.size;

      let url =
        "/dev-api/cfgpermitconfig/query/data/find/cfg_permitinfo_andzchx_list";
      let params = {
        itemtype: "2",
        release: "1",
        ...this.searchInfo.oldParams,
        ["PAGE_CUR"]: this.pageInfo.page.toString(),
        ["PAGE_ROWS"]: this.pageInfo.size.toString(),
      };
      // 特殊处理一下 奖励金额
      switch (params.temp_amount) {
        case "0":
          params.zzxeleft = 1000;
          break;
        case "1":
          params["zzxe_500_1000"] = "1";
          break;
        case "2":
          params["zzxe_100_500"] = "1";
          break;
        case "3":
          params.zzxeright = 100;
          break;
        case "4":
          params.zzxeleft = Number(params.zzxeleft);
          params.zzxeright = Number(params.zzxeright);
          break;
        default:
          break;
      }
      Reflect.deleteProperty(params, "temp_amount");

      this.dataLoading = true;
      this.$httpApi
        .post(url, params)
        .then((res) => {
          console.log("getListData:", res);
          this.dataLoading = false;

          this.pageInfo.total = res.pageInfo.pageCount || 0;
          switch (this.searchInfo.type) {
            case "0":
              this.showList = res.data || [];
              break;
            case "1":
              if (res.data && !!res.data.length) {
                let zzbzUrl =
                  "/dev-api/sqzdManager/data/find/sq_policy_project_zzqx_list";
                let zzbzParams = JSON.parse(JSON.stringify(params));
                zzbzParams.sxids = res.data.map((cv) => cv.id).join(",");
                Reflect.deleteProperty(zzbzParams, "PAGE_CUR");
                Reflect.deleteProperty(zzbzParams, "PAGE_ROWS");

                this.$httpApi.post(zzbzUrl, zzbzParams).then((resp) => {
                  console.log("zzbzData:", resp);
                  this.showList = [];
                  // 处理下序号的问题
                  // 按照政策项目，依次推入
                  for (let i = 0; i < res.data.length; i++) {
                    let temp_zzbzList = resp.data.filter(
                      (cv) => cv.id === res.data[i].id
                    );
                    temp_zzbzList.forEach((zzbz) => {
                      zzbz.serialNumber = i;
                      this.showList.push(zzbz);
                    });
                  }

                  // 获取 企业资质
                  for (let i = 0; i < this.showList.length; i++) {
                    let qyzzUrl =
                      "/dev-api/sqzdManager/data/find/sq_policylabelname_query";
                    let qyzzParams = { labelcodes: this.showList[i].qyzz };
                    this.$httpApi
                      .post(qyzzUrl, qyzzParams)
                      .then((respo) => {
                        // console.log('getQyzz:', respo)
                        if (respo.data && !!respo.data.length) {
                          this.$set(
                            this.showList[i],
                            "qyzz_text",
                            respo.data.map((cv) => cv.name).join(", ")
                          );
                        }
                      })
                      .catch((err) => {
                        console.log("getQyzz error:", err);
                      });
                  }
                });
              }
              break;
            default:
              break;
          }
        })
        .catch((err) => {
          console.log("getListData error:", err);
          this.dataLoading = false;
        });
    },
    handleSizeChange(val) {},
    // 当前页改变时
    CurrentChange(pageNum) {
      let pageSize = this.pageSize;
      let url = "/elastic/search/sq-policy-project";
      let params = {
        pageNum: pageNum,
        pageSize: pageSize,
        keyWords: this.keywords,
        projectName: "",
        deptName: [],
        projectType: [],
        industryType: [],
        supportWay: [],
      };
      let that = this;
      that.$httpApi
        .post(url, params)
        .then((res) => {
          that.total = res.total;
          that.tableData = res.rows;
          that.tableData.forEach((item, index, arr) => {
            arr[index].startTime = item.startTime.split(" ")[0];
            arr[index].endTime = item.endTime.split(" ")[0];
          });
          that.$emit("getTotal", res.total);
        })
        .catch((e) => {});
    },
    getProject() {
      let url = "/elastic/search/sq-policy-project";
      let params = {
        pageNum: 1,
        pageSize: this.pageSize,
        keyWords: this.keywords,
        projectName: "",
        deptName: this.deptName,
        projectType: this.projectType,
        industryType: this.industryType,
        supportWay: this.supportWay,
      };
      let that = this;
      that.$httpApi
        .post(url, params)
        .then((res) => {
          that.total = res.total;
          that.$emit("getTotal", res.total);
          that.tableData = res.rows;
          that.tableData.forEach((item, index, arr) => {
            arr[index].startTime = item.startTime.split(" ")[0];
            arr[index].endTime = item.endTime.split(" ")[0];
          });
        })
        .catch((e) => {});
    },
    // 跳办事指南
    toGuideSuqiu(row) {
      this.$store.commit("updateCurrentGuideId", row.id);
      this.$router.push({
        path: "/guideSuqiu",
        query: {
          name: row.name,
          itemType: row.itemtype,
          isAdded: row.isAdded,
        },
      });
    },
  },
  created() {
    this.$store.dispatch("dictionary/commonSetDic", {
      types: [
        "sq_policy_xmlb",
        "sys_enter_type",
        "sq_policy_zjlx",
        "permit_sertype", // 办理对象
        "sys_enterprise_registertype", // 注册类型
        "sys_category", // 行业类别(国民经济分类)
      ],
    });
    this.getDeptList();
    this.getProject();
  },
  mounted() {
    this.getListData();
  },
};
</script>

<style lang="scss">
@import "@/styles/com/theme.scss";
@import "@/styles/com/flex.scss";
.seperation {
  height: 20px;
  background-color: #fff;
}
.searchList {
  .banner-info {
    height: 100%;
    display: flex;
    align-items: center;
    .input-search-bar {
      height: 55px;
      width: 50%;
      margin-bottom: 10px;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      background: #ffffff;
      margin-left: 74px;

      /* 输入框头部 */
      .input-search-pre {
        width: 120px !important;
        color: #404040;
        font-size: 16px;
        background: $color-fff;
        .el-input__inner {
          text-align: center !important;
          border: none !important;
          border-radius: 0 !important;
          color: #333;
          background-color: #ffffff !important;
        }
      }

      /* 输入框内容 */
      .input-search {
        flex: 1;
        .el-input__inner {
          border: none !important;
          border-radius: 0 !important;
          background-color: #ffffff !important;
        }
        input::-webkit-input-placeholder {
          color: #999999;
        }
      }
      /* 输入框按钮 */
      .input-search-btn {
        background: #e60000;
        height: 100%;
        width: 100px;
        border-radius: 0 !important;
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
        border: 2px solid #b6a99c !important;
        text-align: center;
        font-size: $font-size18;
        color: $color-fff;
        cursor: pointer;
        .el-button {
          font-weight: bold !important;
        }
      }
    }
  }
  .search-bar {
    margin: 40px;
    justify-content: flex-start;
    .s-input {
      width: 40% !important;
    }
  }
  .tabBgcolor {
    width: 100%;
    height: 60px;
    background-color: #df1d09;
    position: absolute;
    top: 248px;
  }
  .tool {
    position: absolute;
    top: 0;
    right: -5px;
    cursor: pointer;
  }
  .search-result {
    width: 1000px;
  }
  .s-container {
    width: 100%;
    min-height: 500px;
    padding: 20px 0;
    .el-menu-demo {
      margin-top: -20px;
    }
    .el-menu--horizontal > .el-menu-item.is-active {
      color: #fff;
      border-bottom: 2px solid #fff;
    }
    .resultTop {
      position: relative;
      cursor: pointer;
      margin-top: 30px;
      .underline {
        text-decoration: underline;
      }
      .toolTitle div {
        margin-right: 15px;
        span {
          margin-right: 15px;
        }
        .boldTxt {
          color: #000;
          font-weight: 700;
        }
      }
      .el-input__inner {
        width: 50%;
        border: none;
        color: #333;
        padding-right: 0;
      }
      .el-input__suffix {
        right: 60px;
      }
      input::-webkit-input-placeholder {
        color: #333;
      }
    }
    .find-list-container {
      margin-top: 14px;
      .el-input {
        input {
          background-color: #ffffff;
          border-radius: 0px !important;
        }
      }
      .el-input-group__append {
        button {
          background-color: rgb(75, 145, 233);
        }
      }
      .el-row {
        width: 360px;
        display: flex;

        .el-button {
          border: 0px;
          border-radius: 0px;
          width: 90px;

          .el-icon-bottom {
            margin-left: 15px;
          }

          &:hover {
            background-color: #2d8cf0;
          }
        }
      }
    }
    .filter-params-inner {
      display: -webkit-flex;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      ::v-deep .el-tag {
        margin-right: 5px;
        color: $col-theme;

        .el-tag__close {
          color: $col-theme;

          &:hover {
            color: #ffffff;
          }
        }
      }
    }
    .resultList {
      width: 100%;
      min-height: 500px;
      .resultList-left {
        // width: 60%;
        min-width: 740px;
        width: 100%;
        min-height: 400px;
        .resultList-left-content {
          margin: 10px auto;
        }
        .title {
          font-size: 18px;
          max-width: 95px;
          margin-right: 5px;
          background-color: $col-blue;
          border-left: 5px solid $col-blue;
          border-right: 5px solid $col-blue;
          font-size: 16px;
          color: #fff;
        }
        .headerTitle{
          font-size: 18px;
          color: #0042a2;
          margin-right: 0px;
        }
        .content {
          // width: 740px;
          width: 100%;
          display: flex;
        }
        .tableDiv {
          // width: 740px;
          width: 100%;
          min-height: 136px;
          border: 1px solid #ccc;
          .row {
            font-size: 18px !important;
            min-height: 40px;
            display: flex;
            border-bottom: 1px solid #ccc;
          }
          .row-last {
            // min-height: 40px;
            border: none;
            padding: 10px;
          }
          .row p {
            padding: 10px;
          }
        }
        .img {
          width: 140px;
          height: 80px;
          margin-right: 20px;
          background-color: #e0e0e0;
        }
        .txt {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
        .subtilte {
          min-width: 1255px;
          margin-top: 20px;
        }
      }
      .el-pager li {
        padding: 0 10px;
        background: #fff;
        border: 1px solid #ccc;
      }
      .el-pager li.active {
        background: #eee;
        color: #666;
      }
      .el-pagination.is-background {
        .btn-next,
        .btn-prev {
          padding: 0 15px;
          background: #fff;
          border: 1px solid #ccc;
        }
      }
      .resultList-right {
        // width: 38%;
        min-width: 250px;
        // background: lightgreen;
        margin-left: 150px;
        align-self: flex-start;
        height: 400px;
        .rank {
          min-height: 300px;
          border-left: 1px solid #ccc;
          padding-left: 20px;
          margin-top: 40px;
          p {
            font-size: 18px;
            padding-bottom: 10px;
          }
          i {
            display: inline-block;
            width: 16px;
            height: 16px;
            text-align: center;
            line-height: 16px;
            color: #fff;
            font-style: normal;
            background-color: #999;
            border-radius: 3px;
            margin-top: 4px;
            margin-right: 5px;
          }
          span {
            color: #333;
          }
          .topthree {
            background-color: #d30b15;
          }
        }
      }
    }
  }
}
.findList {
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: $col-theme;
  }
  .el-checkbox-button.is-checked .el-checkbox-button__inner {
    background-color: red !important;
  }
  .FilterRadio-right span {
    color: red;
  }
  .FilterRadio-right .el-icon-caret-bottom {
    color: red !important;
  }
  .el-checkbox-button__inner:hover {
    color: red;
  }
  .is-checked .el-checkbox-button__inner:hover {
    color: #fff;
  }
  .el-radio-button__inner,
  .filterItem-label {
    font-size: 18px;
  }
  .filterItem-label {
    visibility: visible;
  }
}
::v-deep .el-table th {
  background: none;
}
</style>
