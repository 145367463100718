<template>
  <div>
    <!-- 搜索条件 -->
    <div class="def-container find-list-container">
      <div class="findList" ref="findList">
        <!--筛选-->
        <!-- <div class="find-tabs">
          <FilterGroup @change="changeSearch">
            <FilterItem label="已选择条件:" :needAll="false">
              <div class="filter-params-inner">
                <div class="filterItem-params-left">
                  <span v-if="!cpu_paramsList.length" style="margin-left: 20px"
                    >无</span
                  >
                  <a
                    v-else
                    class="pad-rl-1 search-params-clean"
                    @click="cleanParams"
                    >清空</a
                  >
                  <span
                    style="color: #ff890b; margin-left: 40px; margin-left: 20px"
                    v-for="(item, index) in cpu_paramsList"
                    :key="index"
                  >
                    {{ item.label }}
                  </span>
                </div>
              </div>
            </FilterItem>

            <FilterCheckBox
              v-model="searchInfo.data.dq"
              label="场景标签"
              :list="getDictList('policy_level')"
              valueKey="key"
              :needAll="true"
              :type="1"
              fold
            ></FilterCheckBox>
            <FilterCheckBox
              v-model="searchInfo.data.qysx"
              label="场景名称"
              :list="getDictList('enterprise_attributes')"
              valueKey="key"
              :needAll="true"
              :type="1"
              fold
            ></FilterCheckBox>
            <FilterCheckBox
              v-model="searchInfo.data.qygm"
              label="场景下事项名称"
              :list="getDictList('enterprise_size')"
              valueKey="key"
              :needAll="true"
              :type="1"
              fold
            ></FilterCheckBox>
          </FilterGroup>
          <div class="search_table_con">
            <div class="flex-row-between chosenDiv"></div>
            <div class="seperation"></div>
            <div v-show="isShowTable" class="flex-row chosenDiv bgColor">
              <span>计算结果：</span>
              <div style="color: #ff9570; margin-left: 50px">
                共<span class="numStyle">{{ policyNum }}</span
                >份惠企政策，<span class="numStyle">{{ projectNumTotal }}</span
                >个项目，可获得资助资金总额最高为<span
                  v-if="money"
                  class="numStyle"
                  >: {{ money }}万元</span
                >
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <!-- 企业开办 -->
    <div>
      <p class="qykb-title">企业开办</p>
      <div class="qykb-container">
        <div class="qykb-item"  @click="openDialog(item)"
        v-for="(item,index) in themeList"
        :key="index">{{item.scenename}}</div>
      </div>
      <el-dialog title="" :visible.sync="showDialog">
      <h2>请选择需要办理的事项：</h2>
      <el-table
        :data="gridData"
        :show-header="false"
      >
        <el-table-column
          property="date"
          label=""
        >
          <template slot-scope="scope">
            <div style="display:flex;color:#bd2662">
              <span>{{scope.$index+1}}、</span><p>{{scope.row}}</p>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!--  @size-change="handleSizeChange"
        @current-change="handleCurrentChange" -->
        <!-- :current-page="currentPage4" -->
      <el-pagination
        :page-sizes="[5, 10, 20, 40]"
        :page-size="10"
        prev-text="上一页"
        next-text="下一页"
        layout="prev, pager, next,total, jumper"
        :total="gridTotal"
      >
      </el-pagination>
    </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import {
//   FilterGroup,
//   FilterCheckBox,
//   FilterItem,
// } from "../../policyLibrary/components/filter/index.js";

export default {
  name: "searchList",
  components: {
    // FilterGroup,
    // FilterCheckBox,
    // FilterItem,
  },
  props: {
    highlight: {
      type: Function,
    },
  },
  data() {
    return {
      showDialog: false,
       gridData: [
      ],
      imgSrc: [
        { url: require("@/assets/images/Bananer.png") },
        { url: require("@/assets/images/bananer_bottom.png") },
        { url: require("@/assets/images/banner.jpg") },
        { url: require("@/assets/images/banner_swipe1.png") },
        { url: require("@/assets/images/banner2.png") },
      ],
      dialogVisible: false, //登录提示弹窗
      projectVisible: false, //项目列表展示
      showList: [],
      activeTab: "0", // 3大类 1 审批 2 资金 3 诉求
      collectList: [],
      isShowTable: false,
      // 搜索条件
      searchInfo: {
        data: {
          xmlb: "", // 项目类别
          cylb: "", // 产业类别
          deptcode: "", // 发布部门
          zcfs: "", // 支持方式
          temp_amount: "", // 资金限额
          zzxeleft: "", // 起始资金限额
          zzxeright: "", // 结束资金限额
          name: "", // 事项名称
          hyle: "", //行业类别
          zcfl: "", //政策分类
          dq: "", //地区
          qysx: "", //企业属性
          qygm: "", //企业规模
          growth: "", //成长周期
        },
        oldParams: {},
        deptList: [], // 职能部门列表
        amountList: [
          { value: "0", label: "1000万及以上" },
          { value: "1", label: "500万~1000万" },
          { value: "2", label: "100万~500万" },
          { value: "3", label: "100万及以下" },
          // { value: '4', label: '自定义' },
        ],
        areaList: [], //地区列表
        type: "0", // 搜索类型{'0':传统列表, '1':数字化列表}
      },
      dataLoading: false, // dataLoading状态位
      // 分页
      pageInfo: {
        page: 1,
        size: 7,
        total: 0,
      },
      // 项目分页
      pageInfoProject: {
        pageNum: 1,
        pageSize: 5,
        total: 0,
      },
      searchdata: "", //搜索输入数据
      searchDataHyle: "", //搜索行业类别需要的数据
      searchDataZcfl: "", //搜索政策分类需要的数据
      searchDataDq: "", //搜索地区需要的数据
      searchDataDept: "", //搜索部门需要的数据
      searchDataQysx: "", //搜索企业属性需要的数据
      searchDataQygm: "", //搜索企业规模需要的数据
      searchDataGrowth: "", //搜索成长周期需要的数据

      policyInfo: {
        data: [],
      },
      projectShowList: [], //项目数组
      serachProject: "", //项目搜索数据
      policyId: "", //政策id
      policyNum: "", //政策数量
      projectNumTotal: "", //项目数量
      ksbxm: "", //可申报项目
      wsbxm: "", //未申报项目
      money: "", //资助总额
      themeList:''//场景list
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
      getDictList: "dictionary/getDictList",
      getDictLabel: "dictionary/getDictLabel",
    }),
    /**
     * @description: 已选择的搜索条件列表
     * @return {Array}
     */
    cpu_paramsList() {
      let list = [];
      // 去掉第一个逗号
      list.filter((item) => {
        if (item.label[0] == "，") {
          item.label = item.label.substring(1);
        }
      });
      return list;
    },
    /**
     * @description: 表格 办理对象 显示文本
     * @param {String} bldx 办理对象
     * @return {String}
     */
    cpu_blzcText() {
      return function (bldx) {
        if (!bldx) return "";

        return this.getDictLabel("permit_sertype", bldx);
      };
    },
    /**
     * @description: 表格 企业注册类型 显示文本
     * @param {String} qyzclx 企业注册类型
     * @return {String}
     */
    cpu_qyzclxText() {
      return function (qyzclx) {
        if (!qyzclx) return "";

        let temp_qyzclx = qyzclx
          .split(",")
          .map((cv) => this.getDictLabel("sys_enterprise_registertype", cv))
          .join(",");
        return temp_qyzclx;
      };
    },
    cpu_hylbText() {
      return function (hylb) {
        if (!hylb) return "--";

        return hylb
          .split(",")
          .map((cv) => this.getDictLabel("sys_category", cv))
          .join(", ");
      };
    },
    /**
     * @description: 荣誉信息/资质认证要求 的显示文本
     * @param {String} ryxx 荣誉信息
     * @param {String} zzrzyq 资质认证要求
     * @return {String}
     */
    cpu_ryzzText() {
      return function (ryxx, zzrzyq) {
        if (ryxx && zzrzyq) {
          return `${ryxx} / ${zzrzyq}`;
        } else if (!ryxx && !zzrzyq) {
          return "";
        }
        return ryxx || zzrzyq;
      };
    },
    cpu_noText() {
      return function (val) {
        return val || "--";
      };
    },
  },
  methods: {
    openDialog(item) {
      //
       let obj = JSON.parse(JSON.stringify(item));
     this.gridData= obj.servicename.split(",");
     this.gridData.pop()
     this.gridTotal=this.gridData.length
     this.showDialog = true;
    },
    /**
     * @description 清空已选搜索项
     */
    cleanParams() {
      this.searchDataHyle = "";
      this.searchDataZcfl = "";
      this.searchDataDq = "";
      this.searchDataDept = "";
      this.searchDataQysx = "";
      this.searchDataQygm = "";
      this.searchDataGrowth = "";
      this.showList = [];
      this.pageInfo.total = 0;
      // this.getsearchList(1, this.pageInfo.size);
    },
    /**
     * @description: 删除已选搜索项
     * @param {Object} tag
     */
    closeParams(tag) {
      switch (tag.type) {
        case "temp_amount":
          this.searchInfo.data.temp_amount = "";
          this.searchInfo.data.zzxeleft = "";
          this.searchInfo.data.zzxeright = "";
          break;
        default:
          this.searchInfo.data[tag.type] = "";
          break;
      }
      this.searchInfo.oldParams = JSON.parse(
        JSON.stringify(this.searchInfo.data)
      );

      this.showList = [];
      this.pageInfo.total = 0;
      this.getsearchList(1, this.pageInfo.size);
    },
    //查询所有的收藏列表,之后需要比对事项的收藏状态
    getCollectList() {
      if (this.userInfo) {
        let identify = this.userInfo.tyshxydm;
        let param = {
          identify: identify,
          type: "1,2,3,4,5,6",
          pageNum: 1,
          pageSize: 100,
        };
        let url = "/dev-api/collection/list";

        this.$httpApi
          .get(url, param)
          .then((res) => {
            // console.log('getCollectList:', res)
            if (res.code == 200) {
              this.collectList = res.rows;
            }
          })
          .catch((err) => {});
      }
    },
    /**
     * @description: 切换页码
     * @param {Number} page 页码
     */
    handleCurrentChange(page) {
      // this.showList = [];
      this.policyInfo.data = [];
      this.pageInfo.page = page;
      if (this.searchdata) {
        //搜索框查询
        this.searchPolicy();
      } else {
        this.getsearchList(page, this.pageInfo.size, true); //筛选查询
      }
    },
    handleProjectCurrentChange(page) {
      this.projectShowList = [];
      this.pageInfoProject.pageNum = page;
      this.projectShow(this.policyId);
    },
    gotoLogin() {
      console.log("跳转登录页面");
      this.$router.push({
        path: "/login",
      });
    },
    /**
     * @description: 修改搜索条件
     */
    changeSearch(label, value) {
      // 特殊情况不需要触发搜索
      //     资金限额自定义时
      switch (label) {
        case "行业类别":
          this.searchDataHyle = value;
          break;
        // case "政策分类":
        //   this.searchDataZcfl = value;
        //   break;
        case "政策级别":
          // value = value.substring(1);
          this.searchDataDq = value;
          break;
        // case "主管部门":
        //   this.searchDataDept = value;
        //   break;
        case "企业属性":
          this.searchDataQysx = value;
          break;
        case "企业规模":
          this.searchDataQygm = value;
          break;
        case "成长周期":
          this.searchDataGrowth = value;
          break;
        default:
          break;
      }
      this.searchInfo.oldParams = JSON.parse(
        JSON.stringify(this.searchInfo.data)
      );

      // this.showList = [];
      this.policyInfo.data = [];
      this.pageInfo.total = 0;
      // this.getsearchList(1, 10);
    },
    /**
     * @description 查询职能部门列表
     */
    getDeptList() {
      let url = "/dev-api/sqzdManager/data/find/sq_policy_project_dept_list";
      let params = {};
      this.$httpApi
        .post(url, params)
        .then((res) => {
          // console.log('getDeptList:', res)
          if (res.data && !!res.data.length) {
            this.searchInfo.deptList = res.data || [];
          }
        })
        .catch((err) => {
          console.log("getDeptList error:", res);
        });
    },
    /**
     * @description: 查询政策列表
     */
    getAreaList() {
      let url = "/dev-api/cfginfo/region/cascaderTreeList";
      let params = {};
      let that = this;
      this.$httpApi
        .get(url, params)
        .then((r) => {
          let getdata = that.getTreeFlat(r.rows);
          this.searchInfo.areaList = getdata;
        })
        .catch((e) => {});
    },
    //给treeData扁平化处理拿对应数据
    getTreeFlat(arr, parent = false) {
      const dataList = [];
      const generateList = (data, deepcount) => {
        let num = deepcount;
        if (num < 2) {
          for (let i = 0; i < data.length; i++) {
            const { id, value, label, children } = data[i];
            if (!parent) dataList.push({ id, value, label });
            if (Array.isArray(children) && children.length > 0) {
              if (parent) dataList.push({ id, value, label });
              num++;
              generateList(children, num);
            }
          }
        }
      };
      generateList(arr, 0);
      return dataList;
    },
    // 查询政策
    getsearchList(page, size) {
      this.isShowTable = true;
      this.dataLoading = true;
      let that = this;
      let url = "/dev-api/business/sq-apply/policyLibraryMatching";
      let flag = false;
      setTimeout(function () {
        flag = true;
      }, 4000);
      that.policyId = "";
      let params = {
        areaid: that.searchDataDq, //区划id
        sysCategory: that.searchDataHyle, //行业类别
        enterpriseAttributes: that.searchDataQysx, //企业属性
        enterpriseSize: that.searchDataQygm, //企业规模
        growthCycle: that.searchDataGrowth, //成长周期
        pageNums: that.pageInfo.page,
        pageSizes: that.pageInfo.size,
        status: "1",
        search: that.searchdata,
      };
      if (
        that.searchDataDq == "" &&
        that.searchDataHyle == "" &&
        that.searchDataQysx == "" &&
        that.searchDataQygm == "" &&
        that.searchDataGrowth == ""
      ) {
        params.isChoose = false;
      } else {
        params.isChoose = true;
      }
      this.$httpApi
        .post(url, params)
        .then((r) => {
          that.policyInfo.data = r.pageInfo.list;
          that.policyInfo.data.forEach((item) => {
            item.star = 5;
          });
          let time = setInterval(() => {
            if (flag) {
              that.pageInfo.total = r.pageInfo.total;
              that.policyNum = r.pageInfo.total;
              that.projectNumTotal = r.sqPolicyNum.projectNumTotal;
              that.ksbxm = r.sqPolicyNum.ksbxm;
              that.wsbxm = r.sqPolicyNum.wsbxm;
              that.money = r.sqPolicyNum.money;
              this.dataLoading = false;
              clearInterval(time);
            }
          }, 1000);
        })
        .catch((e) => {});
    },
    toPolicyGuide(row, column) {
      /**
       * 20210419
       * 参考 政策类 点击前往政策项目详情，这边也做一个跳转
       */
      let that = this;
      let data = JSON.stringify(row);
      sessionStorage.setItem("zcjsqData", data);
      this.$router.push({ name: "policyDetails" });
    },
    // 点击办理跳转政策/项目页面
    handleClick() {
      this.$router.push({ name: "policyDetails" });
    },
    // 根据条件查询
    searchPolicy() {
      let that = this;
      let url = "/dev-api/business/sq-apply/policyLibraryMatching";
      that.policyId = "";
      let params = {
        areaid: that.searchDataDq, //区划id
        sysCategory: that.searchDataHyle, //行业类别
        enterpriseAttributes: that.searchDataQysx, //企业属性
        enterpriseSize: that.searchDataQygm, //企业规模
        growthCycle: that.searchDataGrowth, //成长周期
        pageNum: that.pageInfo.page,
        pageSize: that.pageInfo.size,
        status: "1",
        search: that.searchdata,
        dataLoading: false,
      };
      if (
        that.searchDataDq == "" &&
        that.searchDataHyle == "" &&
        that.searchdata == "" &&
        that.searchDataQysx == "" &&
        that.searchDataQygm == "" &&
        that.searchDataGrowth == ""
      ) {
        params.isChoose = false;
      } else {
        params.isChoose = true;
      }
      if (
        that.searchDataDq == "" &&
        that.searchDataHyle == "" &&
        that.searchDataQysx == "" &&
        that.searchDataQygm == "" &&
        that.searchDataGrowth == ""
      ) {
        params.isSearchFlag = true;
      }
      this.$httpApi
        .post(url, params)
        .then((r) => {
          that.policyInfo.data = r.list;
          that.policyInfo.data.forEach((item) => {
            item.star = 5;
          });
          that.pageInfo.total = r.total;
          that.policyNum = r.total;
        })
        .catch((e) => {});
    },
    // 查项目接口
    getItemList(policyid) {
      // this.getsearchList();
    },
    //根据政策id查询项目
    projectShow(policyId) {
      this.policyId = policyId;
      let url = "/dev-api/business/sq-apply/selectProjectList";
      let params = {
        policyId: policyId,
        pageSize: this.pageInfoProject.pageSize,
        pageNum: this.pageInfoProject.pageNum,
        serachProject: this.serachProject,
      };
      let that = this;
      that.$httpApi
        .post(url, params)
        .then((r) => {
          this.projectVisible = true;
          if (r) {
            that.projectShowList = r.list;
            that.pageInfoProject.total = r.total;
            that.policyNum = r.total;
          }
        })
        .catch((e) => {});
    },
    toProject(row) {
      this.$store.commit("updateCurrentGuideId", row.id);
      const { href } = this.$router.resolve({
        path: "/guideSuqiu",
        query: {
          id: row.id,
          // name: item.name,
        },
      });
      window.open(href, "_blank");
    },
     // 按场景分类
    getCjList() {
      let url = "/onething/scene/list";
      let that = this;
      that.$httpApi.get(url).then((r) => {
        that.themeList = r.rows;
        that.$emit("getTotal",r.rows.length);
      });
    },
  },
  created() {
    this.getItemList();
    this.$store.dispatch("dictionary/commonSetDic", {
      types: [
        "sq_policy_xmlb",
        "sys_enter_type",
        "sq_policy_zjlx",
        "permit_sertype", // 办理对象
        "sys_enterprise_registertype", // 注册类型
        "sys_category", // 行业类别(国民经济分类)
        "policy_level", //政策层级
        "enterprise_attributes", //企业属性
        "enterprise_size", //企业规模
        "growth_cycle", //成长周期
      ],
    });
    this.getDeptList();
    this.getAreaList();
    this.getCjList()
    // this.getsearchList(this.pageInfo.page, this.pageInfo.size);
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "@/styles/com/theme.scss";
@import "@/styles/com/flex.scss";
.seperation {
  height: 20px;
  background-color: #fff;
}
.findList {
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: $col-theme;
  }
  .el-checkbox-button.is-checked .el-checkbox-button__inner {
    background-color: red !important;
  }
  .filterItem-right span {
    color: red;
  }
  .filterItem-right .el-icon-caret-bottom {
    color: red !important;
  }
  .el-checkbox-button__inner:hover {
    color: red;
  }
  .is-checked .el-checkbox-button__inner:hover {
    color: #fff;
  }
}
.qykb-title {
  font-weight: 700;
  color: #000;
  font-size: 20px;
  margin-bottom: 10px;
}
.qykb-container {
  width:100%;
  min-height:200px;
  // background-color: #ccc;
  display:flex;
	flex-wrap:wrap;
  .qykb-item {
    font-size: 18px;
    width:250px;
    height: 100px;
    text-align: center;
    display:flex;
    justify-content: center;
    align-items: center;
    margin: 10px ;
    box-shadow: #e2e2e2 0px 0px 10px;
    cursor: pointer;
    position: relative;
    &:hover {
      box-shadow: #ccc 0px 0px 10px;
    }
  }
}
</style>
